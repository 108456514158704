import React from 'react'
import Aside from '../../../../../common/Aside/Aside';
import { AdminHeader } from './AdminHeader';
import { CategoriesComponent } from './CategoriesComponent';
import { useLocation } from 'react-router-dom';
import { ContractorsNavbar } from './ContractorsNavbar';
import { RewardNavbar} from './RewardNavbar';

type Props = {
  children: React.ReactNode;
};

export const AdminLayout = ({children}: Props) => {
   const location = useLocation()

    const contractors = location.pathname.startsWith("/admin/contractors")
   const category = location.pathname.startsWith("/admin/category") || location.pathname.startsWith("/admin/company")
   const reward = location.pathname.startsWith("/admin/reward")
  //  console.log(category,contractors,"contractors,category")


  return (
  <>
  <Aside>
    {category && <AdminHeader /> }
   
    <div className="bg-[#E9ECEF]">
       {category && <CategoriesComponent /> } 
       {contractors && <ContractorsNavbar />}
       {reward && <RewardNavbar />}
        {children}
    </div>
  </Aside>
  </>
  )
}
