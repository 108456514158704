import { Item, Status } from "./types"

import { FaCheckCircle, FaClock, FaCalendarAlt, FaPhone, FaEnvelope } from 'react-icons/fa';
type Props = {
    items: Item[]
}
const steps = [
    { title: "Order Confirmed", value: "pending", Icon: <FaCheckCircle className="text-white text-xl" /> },
    { title: "Accepted", value: "accepted", Icon: <FaClock className="text-white text-xl" /> },
    { title: "Scanned", value: "scanned", Icon: <FaCalendarAlt className="text-white text-xl" /> },
    { title: "Unloading", value: "unloading", Icon: <FaPhone className="text-white text-xl" /> },
    { title: "Weight Done", value: "weightDone", Icon: <FaEnvelope className="text-white text-xl" /> },]

const ProcessingOrder = ({ items }: Props) => {
    console.log('items', items);
    return (
        <>
            <div className="relative w-full hidden md:flex  h-auto flex-col gap-4 justify-center items-center mt-4  ">
                {items?.map((item: Item, itemIndex: number) => {
                    const completedStatus = item.status?.map((status: Status) => status.value) || [];
                    const lastValidStatus = completedStatus.reduce((prev, curr) => {
                        if (steps.some((step) => step.value === curr)) {
                            return curr;
                        }
                        return prev;
                    }, "");

                    const lastCompletedIndex = steps.findIndex((step) => step.value === lastValidStatus);
                    const progressBarWidth = lastCompletedIndex >= 0 ? ((lastCompletedIndex + 1) / steps?.length) * 100 : 0;
                    const taxableValue = item?.taxableValue || 0;
                    const gst = item?.gst || 0;
                    const gstValue = (taxableValue * gst) / 100;
                    const netValue = taxableValue + gstValue;

                    return (
                        <div className="flex flex-col w-full border rounded-lg shadow-lg p-3 " key={itemIndex}>
                            {/* Product Heading */}
                            <h2 className="text-xl font-bold text-start pl-3 py-4 bg-[#5097A4] rounded-lg mb-3 text-white">
                                Product Details
                            </h2>

                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 p-4 border rounded-lg border-gray-200">
                                <div className="text-[#5097A4] font-[600] text-[12px] leading-[20px]">
                                    Item Name:
                                    <span className="text-black font-[600] text-[12px] leading-[20px]">
                                        {item?.item?.name} {item?.item?.cid?.name}
                                    </span>
                                </div>
                                <div className="text-[#5097A4] font-[600] text-[12px] leading-[20px]">
                                    Item Code:
                                    <span className="text-black font-[600] text-[12px] leading-[20px]">
                                        {item?.item?.code}
                                    </span>
                                </div>
                                <div className="text-[#5097A4] font-[600] text-[12px] leading-[20px]">
                                    Dispatch Quantity:
                                    <span className="text-black font-[600] text-[12px] leading-[20px]">
                                        {item?.dispatchQuantity} {item?.unit}
                                    </span>
                                </div>
                                <div className="text-[#5097A4] font-[600] text-[12px] leading-[20px]">
                                    Quantity:
                                    <span className="text-black font-[600] text-[12px] leading-[20px]">
                                        {item?.quantity}
                                    </span>
                                </div>
                                <div className="text-[#5097A4] font-[600] text-[12px] leading-[20px]">
                                    Total Rate:
                                    <span className="text-black font-[600] text-[12px] leading-[20px]">
                                        ₹{netValue.toFixed(2)}
                                    </span>
                                </div>
                                <div className="text-[#5097A4] font-[600] text-[12px] leading-[20px]">
                                    Total Rate x Quantity:
                                    <span className="text-black font-[600] text-[12px] leading-[20px]">
                                        ₹{(netValue * item.quantity).toFixed(2)}
                                    </span>
                                </div>
                            </div>

                            <div className="relative mt-3 flex flex-col justify-between items-center w-full py-[13px] px-[13px] overflow-hidden mb-4">
                                <div className="w-full">
                                    <span className="text-black font-[600] text-[16px] leading-[20px] ">
                                        {item?.item?.name} {item?.item?.cid?.name}
                                    </span>
                                </div>

                                <div className="relative flex flex-col md:flex-row justify-between items-center w-full mt-3">
                                    {/* Progress Bar */}
                                    <div className="md:absolute top-1/4 transform -translate-y-1/2 w-[98%] h-2 border rounded-lg bg-gray-200">
                                        <div
                                            className="bg-[#5097a4] h-full border rounded-lg"
                                            style={{ width: `${progressBarWidth}%` }}
                                        ></div>
                                    </div>

                                    {/* Circles and Steps */}
                                    {steps.map((step, index) => {
                                        const statusData = item?.status.find((status) => status.value === step?.value);
                                        const statusDate = statusData ? statusData?.date : null;
                                        const isCompleted = index <= lastCompletedIndex;

                                        return (
                                            <div key={index} className="flex flex-row md:flex-col items-center w-full relative">
                                                {/* Circle for each step */}
                                                <div className={`w-14 h-14 rounded-full ${isCompleted ? "bg-[#5097a4]" : "bg-gray-300"} flex items-center justify-center`}>
                                                    {step.Icon}
                                                </div>
                                                <p className="text-xs font-semibold mt-2 text-center">{step.title}</p>
                                                {statusDate && (
                                                    <p className="text-xs font-semibold">{new Date(statusDate).toLocaleString()}</p>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

            <div className="relative w-full rounded-lg flex flex-col md:hidden gap-[12px] h-full md:px-2 md:py-2 justify-center items-center ">
                {items?.map((item: Item, itemIndex: number) => {
                    const completedStatus =
                        item.status?.map((status: Status) => status.value) || [];

                    const lastValidStatus = completedStatus.reduce((prev, curr) => {
                        // Reduce the 'completedStatus' array to find the last valid status
                        if (steps.some((step) => step?.value === curr)) {
                            // Check if the current status is present in the 'steps' array
                            return curr; // If it is, return it as the new 'lastValidStatus'
                        }
                        return prev; // If not, keep the previous 'lastValidStatus'
                    }, "");

                    // Find the index of the last valid status in the 'steps' array
                    const lastCompletedIndex = steps?.findIndex(
                        (step) => step.value === lastValidStatus
                    );

                    // Calculate the width of the progress bar based on the index of the last valid status
                    const progressBarWidth =
                        lastCompletedIndex >= 0
                            ? ((lastCompletedIndex + 1) / steps?.length) * 100 // If a valid status is found, calculate the width
                            : 0; // If no valid status is found, set the width to 0


                    return (

                        <div className="flex flex-col w-full border p-3 gap-2 rounded-lg md:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]">
                            <h2 className="text-sm font-bold text-start pl-2 py-2 bg-[#5097A4] rounded-lg mb-3 text-white">
                                Product Details
                            </h2>

                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 p-4 border rounded-lg border-gray-200">
                                <div className="text-[#5097A4] font-[600] text-[12px] leading-[20px]">
                                    Item Name:
                                    <span className="text-black font-[600] text-[12px] leading-[20px]">
                                        {item?.item?.name} {item?.item?.cid?.name}
                                    </span>
                                </div>
                                <div className="text-[#5097A4] font-[600] text-[12px] leading-[20px]">
                                    Item Code:
                                    <span className="text-black font-[600] text-[12px] leading-[20px]">
                                        {item?.item?.code}
                                    </span>
                                </div>
                                <div className="text-[#5097A4] font-[600] text-[12px] leading-[20px]">
                                    Dispatch Quantity:
                                    <span className="text-black font-[600] text-[12px] leading-[20px]">
                                        {item?.dispatchQuantity} {item?.unit}
                                    </span>
                                </div>
                                <div className="text-[#5097A4] font-[600] text-[12px] leading-[20px]">
                                    Quantity:
                                    <span className="text-black font-[600] text-[12px] leading-[20px]">
                                        {item?.quantity}
                                    </span>
                                </div>
                                <div className="text-[#5097A4] font-[600] text-[12px] leading-[20px]">
                                    Total Rate:
                                    <span className="text-black font-[600] text-[12px] leading-[20px]">
                                        {/* ₹{netValue.toFixed(2)} */}
                                    </span>
                                </div>
                                <div className="text-[#5097A4] font-[600] text-[12px] leading-[20px]">
                                    Total Rate x Quantity:
                                    <span className="text-black font-[600] text-[12px] leading-[20px]">
                                        {/* ₹{(netValue * item.quantity).toFixed(2)} */}
                                    </span>
                                </div>
                            </div>
                            <div
                                key={itemIndex}
                                className="relative flex gap-[8px] items-center w-full h-[400px]  px-2 py-2"
                            >
                                <div className="h-full  rounded-full dark:bg-gray-700 relative">

                                    <div
                                        className="bg-[#5097a4] text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full absolute left-4 top-0"
                                        style={{
                                            height: `${progressBarWidth
                                                }%`,
                                        }}
                                    ></div>
                                </div>

                                <div className="flex flex-col gap-[16px] w-full h-auto">
                                    {steps.map((step, index) => {
                                        const statusData =
                                            items?.[0]?.status?.find(
                                                (status: Status) => status.value === step.value
                                            );
                                        const statusDate = statusData ? statusData.date : null;
                                        const isCompleted = index <= lastCompletedIndex
                                        return (
                                            <div
                                                key={index}
                                                className="flex flex-row gap-2 md:flex-col items-center w-full relative"
                                            >
                                                <div
                                                    className={`w-12 h-12 relative rounded-full -ml-[0.93rem] ${isCompleted ? "bg-[#5097a4]" : "bg-gray-300"
                                                        } flex items-center justify-center`}
                                                >{step.Icon}</div>
                                                <p className="text-xs font-semibold mt-2 mx-[4px] text-center">
                                                    {step?.title}
                                                </p>
                                                {statusDate && (
                                                    <p className="text-xs font-semibold mt-2 text-[12px] padding-[16px]">
                                                        {new Date(statusDate).toLocaleString()}
                                                    </p>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                        </div>
                    );
                })}
            </div>
        </>

    )
}

export default ProcessingOrder