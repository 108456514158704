import { useEffect, useRef, useState } from "react";
import { CgProfile } from "react-icons/cg";
import { CiMedicalClipboard } from "react-icons/ci";
import { FaHourglassHalf } from "react-icons/fa6";
import { TbMessageCircleQuestion } from "react-icons/tb";
import { MdCancel, MdOutlineLogout } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../../Redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useContractorAvtarMutation, useLogoutApiMutation } from "../../../Redux/Api/Contractor";
import { LoginResponse } from "../../../Redux/reducer/LoginReducer";
import Profile from "../../../assets/profile/DefualtProfile.png";
import { useCreateImageURLMutation } from "../../../Redux/Api/Admin/Image";
import { LiaEditSolid } from "react-icons/lia";
import { toast } from "react-toastify";
import { AnimatePresence, motion } from "framer-motion";
import { FaRegListAlt } from "react-icons/fa";
import useLogout from "../../../hooks/useLogout";

export const ProfileSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [sidebar, setSidebar] = useState<string>(location.pathname);
  const userDetails = useSelector((state: RootState) => state.Login.ContractorData);

  const [getImageURL, { data, isLoading: isUploading }] = useCreateImageURLMutation();
  const [mutateImage, { isLoading: isUpdating }] = useContractorAvtarMutation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file) {
      setUploadedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
      setShowPopup(true);
    }
  };

  const handleSubmit = async () => {
    if (uploadedFile) {
      const body = new FormData();
      body.append("image", uploadedFile);
      try {
        const response = await getImageURL(body).unwrap();
        mutateImage({ image: response[0] });
        toast.success("Profile image updated successfully!", {
          position: "top-center",
        });
        handleClosePopup();
      } catch (error) {
        toast.error("Failed to update profile image. Please try again later.", {
          position: "top-center",
        });
        console.error("Error uploading image:", error);
      }
    }
  };

  const handleEditClick = () => {
    fileInputRef.current?.click();
  };


  useEffect(() => {
    setSidebar(location.pathname);
  }, [location.pathname]);

  const handleRouting = (pathname: string) => {
    navigate(pathname);
    setSidebar(pathname);
  };

  const { handleLogout } = useLogout()

  // const [LogoutCall, { data: logoutData }] = useLogoutApiMutation();

  // const handleLogout = async () => {
  //   const result = await LogoutCall().unwrap();
  //   if (result?.success) {
  //     dispatch(LoginResponse(null));
  //     navigate("/login");
  //   }
  // };

  // useEffect(() => {
  //   if (logoutData && logoutData.success) {
  //     dispatch(LoginResponse(null));
  //     navigate("/login");
  //   }
  // }, [logoutData, dispatch, navigate]);

  const handleClosePopup = () => {
    setShowPopup(false);
    setUploadedFile(null);
    setImagePreview(null);
  };



  return (
    <div className="px-[12px] lg:flex flex-col justify-between  w-[266px] h-[80vh] pb-[10px] hidden" style={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}
    >
      <div className="flex flex-col gap-[14px] py-[12px]" >
        <div className="py-[10px] px-[8px] gap-[10px] flex flex-col items-center border-b">
          {userDetails?.isVerified && (
            <div className=" relative ml-[4px] hidden sm:block">
              <label htmlFor="profile-upload">
                <input
                  id="profile-upload"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                />
              </label>
              <div className="w-12 h-12 border-2 rounded-full bg-white text-turkishBlue-dark font-bold text-xl text-center flex items-center  justify-center">
                <img src={userDetails?.avatar || Profile} alt="profile" className="rounded-full h-full w-full object-cover" />
              </div>
              <div onClick={handleEditClick} className="absolute bottom-0 right-[-5px] rounded-full p-1 h-6 w-6  flex justify-center items-center cursor-pointer bg-white border" ><LiaEditSolid /></div>
            </div>
          )}
          <span className="font-bold text-[14px] leading-[20px]">
            {userDetails?.name}
          </span>
        </div>
        <div className="text-[#6B7280] space-y-2">
          {/* My Profie  */}
          <div className={`px-[13px] py-[9px] flex gap-[10px] items-center rounded-[8px] hover:bg-gray-100 hover:text-[#6B7280] ${sidebar === "/profile" ? "bg-[#5097A4] rounded-[8px] border-[3px] border-[#E7E7E7] text-white" : ""
            }`}
            onClick={() => handleRouting("/profile")}
          >
            <CgProfile className="cursor-pointer" />
            <span className="font-medium text-[15px] leading-[19px] cursor-pointer">
              My Profile
            </span>
          </div>
          {/* Reward Cards */}
          <div className={`px-[13px] py-[9px] flex gap-[10px] items-center rounded-[8px] hover:bg-gray-100 hover:text-[#6B7280] ${sidebar === "/profile/rewards" ? "bg-[#5097A4] rounded-[8px] border-[3px] border-[#E7E7E7] text-white" : ""
            }`}
            onClick={() => handleRouting("/profile/rewards")}
          >
            <TbMessageCircleQuestion className="cursor-pointer" />
            <span className="font-medium text-[15px] leading-[19px] cursor-pointer">
              Reward Cards
            </span>
          </div>
          {/* Customer Entry */}
          <div className={`px-[13px] py-[9px] flex gap-[10px] items-center rounded-[8px] hover:bg-gray-100 hover:text-[#6B7280] ${sidebar === "/profile/entry" ? "bg-[#5097A4] rounded-[8px] border-[3px] border-[#E7E7E7] text-white" : ""
            }`}
            onClick={() => handleRouting("/profile/entry")}
          >
            <CiMedicalClipboard className="cursor-pointer" />
            <span className="font-medium text-[15px] leading-[19px] cursor-pointer">
              Customer Entry
            </span>
          </div>
          {/* History */}
          <div className={`px-[13px] py-[9px] flex gap-[10px] items-center rounded-[8px] hover:bg-gray-100 hover:text-[#6B7280] ${sidebar === "/profile/history" ? "bg-[#5097A4] rounded-[8px] border-[3px] border-[#E7E7E7] text-white" : ""
            }`}
            onClick={() => handleRouting("/profile/history")}
          >
            <FaHourglassHalf className="cursor-pointer" />
            <span className="font-medium text-[15px] leading-[19px] cursor-pointer">
              History
            </span>
          </div>
          {/* Quotation Tracker */}
          <div
            className={`px-[13px] py-[9px] flex gap-[10px] items-center rounded-[8px] hover:bg-gray-100 hover:text-[#6B7280] ${sidebar === "/profile/quotation-tracker" ? "bg-[#5097A4] rounded-[8px] border-[3px] border-[#E7E7E7] text-white" : ""
              }`}
            onClick={() => handleRouting("/profile/quotation-tracker")}
          >
            <FaRegListAlt className="cursor-pointer" />
            <span className="font-medium text-[15px] leading-[19px] cursor-pointer">
              Quotation Tracker
            </span>
          </div>

          {/* Query */}
          <div className={`px-[13px] py-[9px] flex gap-[10px] items-center rounded-[8px] hover:bg-gray-100 hover:text-[#6B7280] ${sidebar === "/profile/query" ? "bg-[#5097A4] rounded-[8px] border-[3px] border-[#E7E7E7] text-white" : ""
            }`}
            onClick={() => handleRouting("/profile/query")}
          >
            <TbMessageCircleQuestion className="cursor-pointer" />
            <span className="font-medium text-[15px] leading-[19px] cursor-pointer">
              Query
            </span>
          </div>
        </div>
      </div>

      {/* Logout */}
      <div
        className="rounded-[8px] border px-[13px] py-[9px] font-medium cursor-pointer flex gap-[10px] items-center text-[#6B7280] bg-slate-50"
        onClick={handleLogout}
      >
        <MdOutlineLogout className="text-black" />
        <span>Logout</span>
      </div>

      {/* Image Upload Popup */}
      {showPopup && (
        <AnimatePresence>
          <motion.div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <motion.div
              className="bg-white rounded-lg text-center py-8 w-[250px] flex justify-center flex-col items-center relative"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
              transition={{ duration: 0.3 }}
            >
              <span
                onClick={handleClosePopup}
                className="absolute right-2 top-2 bg-black z-50 rounded-full text-white cursor-pointer"
              >
                <MdCancel />
              </span>
              <h2 className="text-lg font-semibold mb-4">Image Preview</h2>
              {imagePreview && (
                <img
                  src={imagePreview}
                  alt="Uploaded Preview"
                  className="w-32 h-32 border-4 border-[#5097A4] rounded-full mb-4 object-cover object-center"
                />
              )}
              <div className="flex justify-center gap-3">
                <button
                  onClick={handleSubmit}
                  className="bg-[#5097A4] hover:bg-[#5097A4] text-white text-[14px] font-bold py-1 px-3 rounded"
                >
                  {isUploading || isUpdating ? "Saving..." : "Save"}
                </button>
              </div>
            </motion.div>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
};
