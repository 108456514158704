import { useEffect, useState } from "react";
import {  useGetAdminContractorRewardMutation } from "../../../Redux/Api/Order";
import { useNavigate, useParams } from "react-router-dom";

import { BiCoinStack } from "react-icons/bi";

const AdminContractorReward = () => {
    const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();

	const [mutation, { data}] =
    useGetAdminContractorRewardMutation();

	useEffect(() => {
		mutation(id);
	}, [mutation, id]);



  return (
    <div className="w-full overflow-x-auto">
    <table className="table-auto min-w-full divide-y divide-gray-200 mt-2 relative">
      <thead className="bg-white divide-y divide-gray-200">
        <tr className="border-t">          
          <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
            Reward Package
          </th>
          <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
            Approve BY
          </th>
          <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
            Approve Date & Time
          </th>
          <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
            Status
          </th>
        </tr>
      </thead>
      <tbody className="bg-white">
        {data?.rewardData ? (
          data?.rewardData.map((reward: any) => (
            <tr key={reward._id} className="border-t">            

              <td className="px-6 py-4 whitespace-nowrap text-sm text-[#1E293B] flex items-center">
                <img
                  src={reward.packageImage}
                  alt={reward.packageName}
                  className="w-12 h-12 object-cover rounded-full mr-3"
                />
                {reward.packageName}
              </td>

              <td className="px-6 py-4 whitespace-nowrap text-sm text-[#1E293B]">
                <span className="flex items-center">
                  {reward.adminName}
                </span>
              </td>

              <td className="px-6 py-4 whitespace-nowrap text-sm text-black">
                <span className="flex gap-3 items-center">
                  <span>{reward.updatedAt.split("T")[0]}</span>
                  <span className="text-gray-600">{reward.updatedAt.split("T")[1].split(".")[0]}</span>
                </span>
              </td>

              <td className="px-6 py-4 whitespace-nowrap text-sm text-[#1E293B]">
                {reward.claimStatus ? (
                  <button className="bg-green-700 text-white px-4 py-2 rounded hover:bg-blue-600">
                    Claimed
                  </button>
                ) : (
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                  >
                    Approve
                  </button>
                )}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td
              colSpan={6}
              className="py-4 text-center text-lg font-medium text-red-500"
            >
              No rewards available.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
  )
}

export default AdminContractorReward