import { useNavigate } from "react-router-dom";
import { Products } from "./types";
import icon from "../../../assets/Icon.png";
import whatsapp from "../../../assets/whatsappicon.svg";
import sheild from "../../../assets/Shield Done.png";
import defaultImage from "../../../assets/defaultImage.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";

const ItemCard: React.FC<{ data: Products }> = ({ data }) => {
	const id = data?.id;
	const navigate = useNavigate();
	const userDetails = useSelector((state: RootState) => state.Login.ContractorData);

	const handleWhatsappClick = () => {
		const phoneNumber = "919258294692";
		const url = `https://wa.me/${phoneNumber}`;
		window.open(url, "_blank");
	};
	const handleProductClick = () => {
		navigate(`/singleproduct/${id}`, { state: { id } });
	};



	const backgroundStyle = {
		background: "linear-gradient(319.16deg, rgba(68, 167, 185, 0.1) -456.66%, #FFFFFF 96.11%)",
		boxShadow: "0px 1px 10.1px 0px #00000047",
	};

	return (

		<>

			<div className="flex gap-[1rem] border-[0.1px] sm:gap-[2rem] p-2 sm:px-4 sm:py-[15px] w-full mb-3 relative group sm:hover:bg-[#d0f0f5]  duration-300   rounded-md  shadow-md sm:hover:shadow-lg" >
				<div
					onClick={handleProductClick}
					className="cursor-pointer relative w-[170px] h-[170px] md:w-[197px] md:h-[197px] overflow-hidden rounded-md  p-1 ring-1 " style={backgroundStyle}>
					<img
						src={
							Array.isArray(data?.images)
								? data?.images?.length > 0
									? data?.images[0]
									: typeof data?.images === "string"
										? data?.images
										: defaultImage
								: data?.images
									? data?.images
									: defaultImage
						}
						alt="item"
						className="w-[170px] h-full  md:w-[197px] object-cover rounded-md  border-2"
					/>
					{data?.companyImage[0] ? (
						<div className="flex w-[50px] h-[50px] sm:w-[60px] sm:h-[60px] border-[3px]  z-10 ring-1 ring-[#5097A4] bg-white absolute bottom-2 right-2 rounded-full   flex-col items-center justify-center gap-1 sm:group-hover:opacity-0 sm:group-hover:translate-y-2 transition-all duration-300 ease-in-out">
							<img
								src={data?.companyImage[0]}
								alt=""
								className="w-full h-full object-contain rounded-full"
							/>
						</div>
					) : (
						<div className=" flex w-[50px] h-[50px] sm:w-[60px] sm:h-[60px] z-10 border-[3px] border-[#24FF00] absolute bottom-1 right-1 rounded-full bg-green-100 lg:bg-green-100   flex-col items-center justify-center gap-1 sm:group-hover:opacity-0 sm:group-hover:translate-y-2 transition-all duration-300 ease-in-out">
							<img
								src={sheild}
								alt="Shield Icon"
								className="w-3 h-3"
							/>
							<div className="text-[6.5px] sm:text-[8px] text-wrap text-center font-medium text-[#047857]">
								CIPL <br /> Verified
							</div>
						</div>
					)}
					<div className="absolute top-0 w-full left-0 z-20 py-2 px-1 rounded-br-[100px] flex items-center sm:justify-center bg-white gap-[10px] sm:group-hover:opacity-0 sm:group-hover:-translate-y-2 transition-all duration-300 ease-in-out">
						{data?.companyImage[0] ? (
							<>
								<div className="hidden sm:flex whitespace-nowrap text-wrap overflow-hidden text-center text-green-700 text-xs font-[700]">
									{(() => {
										const companyName =
											data?.companyName || "";
										return companyName.length > 17
											? companyName.slice(0, 17) + "..."
											: companyName;
									})()}
								</div>
								<div className="flex sm:hidden whitespace-nowrap text-wrap overflow-hidden  text-green-700 text-xs font-[700]">
									{(() => {
										const companyName =
											data?.companyName || "";
										return companyName.length > 12
											? companyName.slice(0, 12) + "..."
											: companyName;
									})()}
								</div>
							</>
						) : (
							<>
								<img
									src={sheild}
									alt="sheild"
									className="w-[13px] h-[15px] object-cover"
								/>
								<div className="flex whitespace-nowrap overflow-hidden text-green-700 text-[14px] font-[400]">
									CIPL Verified
								</div>
							</>
						)}
					</div>
				</div>

				<div className="flex w-full justify-between py-1">
					<div className="flex flex-col gap-[5px] sm:gap-[10px]  ">
						<h3
							className=" hidden sm:block  text-slate-900 font-semibold text-sm sm:text-base cursor-pointer"
							onClick={handleProductClick}>
							{data?.productName}
						</h3>
						<h3
							className="block sm:hidden  text-slate-900 font-semibold text-sm cursor-pointer"
							onClick={handleProductClick}
						>
							{data?.productName?.length > 23
								? `${data.productName.slice(0, 23)}...`
								: data?.productName}
						</h3>


						<div className="flex flex-col  gap-[5px]">
							<div className="font-[500] text-[14px] leading-[17px] flex  items-center gap-[10px] ">
								{
									userDetails ? (
										data?.finalPrice !== null ? (
											`₹${data?.finalPrice.toFixed(2)}/${data?.unit}`
										) : (
											<span className="font-medium text-[10px] sm:text-[16px] text-turkishBlue-default">Stay Tuned For Your Best Price</span>
										)
									) : (
										<span className="font-medium text-[10px] sm:text-[16px]">Login To View Best Price</span>
									)
								}


							</div>

							<div className="font-400 text-[10px] sm:text-[14px] leading-[22px] sm:mt-1 text-blue-400 flex items-center gap-2">
								<span>
									{" "}
									(For Best Rate , Please Contact Us)
								</span>
								<img
									src={whatsapp}
									className="w-[22px] h-[22px] cursor-pointer"
									onClick={handleWhatsappClick}
									alt="whatsapp"
								/>
							</div>
						</div>

						{data?.rewards ? (
							<div className="flex flex-wrap items-center  justify-start">
								<span className="text-xs sm:text-sm  lg:text-lg w-full sm:w-auto">
									Use Referral code to earn
								</span>
								<div className="flex items-center mt-1 sm:mt-0 sm:ml-1">
									<img
										className="w-3 h-3 sm:w-5 sm:h-5 md:w-6 md:h-6"
										src={icon}
										alt="Coin icon"
									/>
									<span className="text-sm sm:text-lg md:text-xl  font-semibold mx-1 sm:mx-2">
										{data?.rewards}
									</span>
									<span className="text-xs sm:text-sm md:text-base lg:text-lg">
										super coins
									</span>
								</div>
							</div>
						) : null}
						<div className="xl:absolute top-2 right-3">

							{data.status ? (
								<button className=" cursor-default bg-green-500 text-white text-nowrap text-[10px] sm:text-xs px-2 py-1 rounded-md sm:rounded-3xl">
									Available
									In Stock
								</button>
							) : (
								<button className="cursor-default bg-red-500 text-white text-nowrap text-[10px] sm:text-xs px-2 py-1 rounded-md sm:rounded-3xl">

									Awaiting Restock
								</button>
							)}
						</div>
					</div>

				</div>
			</div>




			{/* old */}
			{/* <div className=" flex gap-[2rem] py-[15px] border-t border-b w-full ">
				<div className="flex gap-[2rem] py-[15px]  w-full ">
					<div
						onClick={handleProductClick}
						className="cursor-pointer relative w-[170px] h-[170px] md:w-[197px] md:h-[197px] overflow-hidden">
						<img
							src={
								Array.isArray(data?.images)
									? data?.images?.length > 0
										? data?.images[0]
										: typeof data?.images === "string"
											? data?.images
											: defaultImage
									: data?.images
										? data?.images
										: defaultImage
							}
							alt="item"
							className="w-[190px] h-full object-cover"
						/>
						{data?.companyImage[0] ? (
							<div className="flex w-[50px] h-[50px] sm:w-[60px] sm:h-[60px] border-[3px]  z-10 ring-1 ring-[#5097A4] bg-white absolute bottom-1 right-1 rounded-full   flex-col items-center justify-center gap-1">
								<img
									src={data?.companyImage[0]}
									alt=""
									className="w-full h-full object-contain rounded-full"
								/>
							</div>
						) : (
							<div className=" flex w-[50px] h-[50px] sm:w-[60px] sm:h-[60px] z-10 border-[3px] border-[#24FF00] absolute bottom-1 right-1 rounded-full bg-green-100 lg:bg-green-100   flex-col items-center justify-center gap-1">
								<img
									src={sheild}
									alt="Shield Icon"
									className="w-3 h-3"
								/>
								<div className="text-[6.5px] sm:text-[8px] text-wrap text-center font-medium text-[#047857]">
									CIPL <br /> Verified
								</div>
							</div>
						)}
						<div className="absolute top-0 w-full left-0 z-20 py-2 px-1 rounded-br-[100px] flex items-center sm:justify-center bg-white gap-[10px] ">
							{data?.companyImage[0] ? (
								<>
									<div className="hidden sm:flex whitespace-nowrap text-wrap overflow-hidden text-center text-green-700 text-xs font-[700]">
										{(() => {
											const companyName =
												data?.companyName || "";
											return companyName.length > 17
												? companyName.slice(0, 17) + "..."
												: companyName;
										})()}
									</div>
									<div className="flex sm:hidden whitespace-nowrap text-wrap overflow-hidden  text-green-700 text-xs font-[700]">
										{(() => {
											const companyName =
												data?.companyName || "";
											return companyName.length > 12
												? companyName.slice(0, 12) + "..."
												: companyName;
										})()}
									</div>
								</>
							) : (
								<>
									<img
										src={sheild}
										alt="sheild"
										className="w-[13px] h-[15px] object-cover"
									/>
									<div className="flex whitespace-nowrap overflow-hidden text-green-700 text-[14px] font-[400]">
										CIPL Verified
									</div>
								</>
							)}
						</div>
					</div>

					<div className="flex w-full justify-between">
						<div className="flex flex-col gap-[12px] ">
							<div
								className="font-[500] text-[12px] sm:text-[18px] leading-[21px] cursor-pointer"
								onClick={handleProductClick}>
								{data?.productName}
							</div>

							<div className="flex flex-col  gap-[8px]">
								<div className="font-[500] text-[14px] leading-[17px] flex  items-center gap-[10px] ">
									{
										userDetails ? (
											data?.finalPrice !== null ? (
												`₹${data?.finalPrice.toFixed(2)}/${data?.unit}`
											) : (
												<span className="font-semibold sm:text-[16px] text-turkishBlue-default">Stay Tuned For Your Best Price</span>
											)
										) : (
											<span className="font-semibold sm:text-[16px]">Login To View Best Price</span>
										)
									}


								</div>

								<div className="font-400 text-[10px] sm:text-[14px] leading-[22px] text-blue-400 flex items-center gap-2">
									<span>
										{" "}
										(For Best Rate , Please Contact Us)
									</span>
									<img
										src={whatsapp}
										className="w-[22px] h-[22px] cursor-pointer"
										onClick={handleWhatsappClick}
										alt="whatsapp"
									/>
								</div>
							</div>

							{data?.rewards ? (
								<div className="flex flex-wrap items-center justify-center text-center sm:justify-start sm:text-left">
									<span className="text-xs sm:text-sm md:text-base lg:text-lg w-full sm:w-auto">
										Use Referral code to earn
									</span>
									<div className="flex items-center mt-1 sm:mt-0 sm:ml-1">
										<img
											className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6"
											src={icon}
											alt="Coin icon"
										/>
										<span className="text-base sm:text-lg md:text-xl lg:text-2xl font-semibold mx-1 sm:mx-2">
											{data?.rewards}
										</span>
										<span className="text-xs sm:text-sm md:text-base lg:text-lg">
											super coins
										</span>
									</div>
								</div>
							) : null}

							<div className="flex text-center text-[#007185]  items-center text-[10px] ">
							<div className="flex   pr-[12px] font-[400] text-[10px] sm:text-[14px] leading-[16px] text-[#007185]">
								<FaStar className="text-yellow-600" />{" "}
								<FaStar className="text-yellow-600" />{" "}
								<FaStar className="text-yellow-600" />{" "}
								<FaStar className="text-yellow-600" />{" "}
								<FaStarHalfStroke className="text-yellow-600" />
							</div>
							<IoIosArrowDown className="text-black" />
							<span className="text-[10px] sm:text-[14px]">
								1,084
							</span>
						</div>
						</div>

						{data.status ? (
							<button className=" flex w-fit h-fit gap-2 px-[8px] py-[6px] border-2 border-green-400 rounded-xl  p-1  bg-green-100 text-[8px] sm:text-[14px] font-medium text-green-400 text-nowrap">
								<span>
									<IoIosCheckmarkCircle className="text-[14px] sm:text-[20px] text-green-500" />
								</span>
								<span className="hidden sm:block">Available</span>{" "}
								<span>In Stock</span>
							</button>
						) : (
							<button className="flex justify-center items-center w-fit h-fit gap-2 px-[8px] py-[6px] border-2 border-red-400 rounded-xl  p-1  bg-red-100 text-[8px] sm:text-[14px] font-medium text-red-400 text-nowrap flex-wrap">
								<span>
									<MdOutlineCancel className="text-[12px] sm:text-[18px] text-red-500" />
								</span>

								Awaiting 	<br className="sm:hidden" /> Restock
							</button>
						)}
					</div>
				</div>
			</div> */}
		</>
	);
};

export default ItemCard;
