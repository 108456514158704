import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { usePostMessageMutation } from "../../../Redux/Api/Contractor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm, Controller } from "react-hook-form";
import { LuChevronDown } from "react-icons/lu";
import { formatDate } from "../../../utils/formatDateTime";
import { useClickOutside } from "../../../hooks/useClickOutside";

interface FormData {
  name: string;
  number: string | number | null;
  time: string;
  date: string;
  message: string;
}

export const CustomerEntry = () => {
  const today = new Date();
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const datePickerRef = useRef<HTMLDivElement | null>(null);
  const [mutation, { isLoading }] = usePostMessageMutation();

  const { control, handleSubmit, setValue, watch, reset, formState: { errors } } = useForm<FormData>({
    defaultValues: {
      name: "",
      number: "",
      time: "12:00 AM",
      date: formatDate(today),
      message: "",
    },
  });

  const formData = watch();

  const toggleCalendar = (): void => setShowCalendar(true);

  const handleDateChange = (date: Date | null) => {
    if (date && date <= new Date()) {
      setStartDate(date);
      setValue("date", formatDate(date));
    }
    setShowCalendar(false);
  };

  useClickOutside(datePickerRef, () => setShowCalendar(false))

  const handleTimeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value, id } = event.target;
    const [hours, minutes, period] = formData.time.split(/[: ]/);
    let newTime: string;

    switch (id) {
      case "hours":
        newTime = `${value}:${minutes} ${period}`;
        break;
      case "minutes":
        newTime = `${hours}:${value} ${period}`;
        break;
      case "period":
        newTime = `${hours}:${minutes} ${value}`;
        break;
      default:
        newTime = formData.time;
    }

    setValue("time", newTime);

  };

  const handleForm = (data: FormData) => {
    mutation(data).then(() => {
      reset({
        name: "",
        number: "",
        time: "12:00 AM",
        date: formatDate(today),
        message: "",
      });
      toast.success("Entry Registered!");
    });
  };

  const styles = {
    boxShadowStyle: {
      boxShadow: "0px 0.2px 10px 0px #00000010",
    },
  };
  return (

    <div className="profile-container">
      <div className="text-gray-900 text-2xl font-medium mb-4 text-center sm:text-start">
        Enter Customer Details
      </div>
      <div className="w-full  relative max-w-[1536px]  rounded-md border mb-10 " style={styles.boxShadowStyle}>
        <div className=" grid grid-cols-1 gap-x-14 gap-y-8 sm:grid-cols-6 px-8 py-4">
          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm/6 font-medium text-gray-900">
              First name
            </label>
            <div className="mt-2">
              <Controller
                control={control}
                name="name"
                rules={{
                  required: "Name is required",
                  minLength: {
                    value: 2,
                    message:
                      "Name must be at least 2 characters",
                  },
                  maxLength: {
                    value: 50,
                    message:
                      "Name must be 50 characters or less",
                  },
                  pattern: {
                    value: /^[A-Za-z\s]+$/i,
                    message:
                      "Name can only contain letters and spaces",
                  },
                }}
                render={({ field }) => (
                  <input
                    type="text"
                    placeholder="Enter Customer Name..."
                    className="block w-full   py-1.5 text-gray-900 border-gray-900 focus:border-b-[1.5px]  focus:outline-none border-b-[1.5px] ring-gray-300 placeholder:text-gray-400 sm:text-sm/6"
                    {...field}
                  />
                )}
              />
              {errors.name && (
                <p className="text-red-500 text-sm/6">
                  {errors.name.message}
                </p>
              )}
            </div>
          </div>
          <div className="sm:col-span-3">
            <label
              htmlFor="last-name"
              className="block text-sm/6 font-medium text-gray-900">
              Number
            </label>
            <div className="mt-2">
              <Controller
                control={control}
                name="number"
                rules={{
                  required: "Contact Number is required",
                  pattern: {
                    value: /^\d{10}$/,
                    message:
                      "Contact Number must be exactly of 10 Numbers",
                  },
                }}
                render={({ field }) => (
                  <input
                    type="text"
                    placeholder="Enter Customer Number"
                    className="block w-full   py-1.5 text-gray-900 border-gray-900 focus:border-b-[1.5px]  focus:outline-none border-b-[1.5px] ring-gray-300 placeholder:text-gray-400 sm:text-sm/6"
                    value={field.value ?? ""}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    ref={field.ref}
                  />
                )}
              />
              {errors.number && (
                <p className="text-red-500 text-sm/6">
                  {errors.number.message}
                </p>
              )}
            </div>
          </div>
          <div className="sm:col-span-3">
            <label
              htmlFor="email"
              className="block text-sm/6 font-medium text-gray-900">
              Arrival Time
            </label>
            <div className="mt-2">
              <div className="flex gap-[10px] w-full  text-sm/6 mt-1">
                <div className="relative w-full">
                  <select
                    id="hours"
                    value={formData.time.split(":")[0]}
                    onChange={handleTimeChange}
                    className="w-full border px-[10px]   py-1.5 rounded-md shadow-sm text-gray-900 appearance-none cursor-pointer focus:outline-none focus:border">
                    {Array.from(
                      { length: 12 },
                      (_, i) => (
                        <option
                          key={i}
                          value={i + 1}>
                          {i + 1 < 10
                            ? `0${i + 1}`
                            : i + 1}
                        </option>
                      )
                    )}
                  </select>
                  <span className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">
                    <LuChevronDown />
                  </span>
                </div>

                <div className="relative w-full">
                  <select
                    id="minutes"
                    value={
                      formData.time
                        .split(":")[1]
                        .split(" ")[0]
                    }
                    onChange={handleTimeChange}
                    className="w-full border px-[10px]   py-1.5 rounded-md shadow-sm text-gray-900 appearance-none cursor-pointer focus:outline-none focus:border">
                    {Array.from(
                      { length: 60 },
                      (_, i) => (
                        <option key={i} value={i}>
                          {i < 10 ? `0${i}` : i}
                        </option>
                      )
                    )}
                  </select>
                  <span className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none ">
                    <LuChevronDown />
                  </span>
                </div>

                <div className="relative w-full">
                  <select
                    id="period"
                    value={formData.time.split(" ")[1]}
                    onChange={handleTimeChange}
                    className="w-full border px-[10px]   py-1.5 rounded-md shadow-sm text-gray-900 appearance-none cursor-pointer focus:outline-none focus:border">
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                  <span className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">
                    <LuChevronDown />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:col-span-3 text-sm/6 ">
            <label
              htmlFor="email"
              className="block text-sm/6 font-medium text-gray-900">
              Arrival Date
            </label>
            <div
              id="date"
              className="block w-full  relative mt-2  py-1.5 text-gray-900 border-gray-900 focus:border-b-[1.5px]  focus:outline-none border-b-[1.5px] ring-gray-300 placeholder:text-gray-400 sm:text-sm/6"
              onClick={toggleCalendar}
              ref={datePickerRef}>
              <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                className="w-full  py-1.5 border-none outline-none"
                dateFormat="MMMM d, yyyy"
                open={showCalendar}
                showMonthDropdown
                showYearDropdown
                minDate={new Date("2000-01-01")}
                maxDate={new Date()}
              />
              <FaCalendarAlt className="absolute right-[10px] top-[50%] transform -translate-y-[50%] text-gray-500" />
            </div>
          </div>
          <div className="sm:col-span-6">
            <label
              htmlFor="last-name"
              className="block text-sm/6 font-medium text-gray-900">
              Arrival Description
            </label>
            <div className="w-full h-[137px] border rounded-md text-sm/6 mt-2">
              <Controller
                control={control}
                name="message"
                rules={{ required: "Message is required" }}
                render={({ field }) => (
                  <textarea
                    id="message"
                    className="w-full h-full border-[1.5px] border-gray-800 focus:outline-none focus:border-2 rounded-md px-3 py-1 font-[400]"
                    rows={4}
                    placeholder="Write Your Message...."
                    {...field}
                  />
                )}
              />
              {errors.message && (
                <p className="text-red-500">
                  {errors.message.message}
                </p>
              )}
            </div>
          </div>
          <div className="sm:col-span-6">
            <div className=" flex items-center justify-center gap-x-6">
              <button
                type="submit"
                onClick={handleSubmit(handleForm)}
                disabled={isLoading}
                className={`rounded-md px-10 py-2 text-sm font-semibold text-white shadow-sm mb-4 ${isLoading
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-[#5097A4] hover:bg-opacity-85"
                  }`}>
                {isLoading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
