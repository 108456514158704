import React, { useEffect, useRef, useState } from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { RiUploadCloud2Line } from "react-icons/ri";
import { FieldError, useForm } from "react-hook-form";
import { useContractorMutation } from "../../../Redux/Api/Contractor";
import {  toast } from "react-toastify";
import { Link } from "react-router-dom";

// Update the FormValues type
type FormValues = {
  name: string;
  number: string;
  message: string;
  image: File | null;
}

export const Query = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [formState, setFormState] = useState<FormValues>({
    name: "",
    number: "",
    message: "",
    image: null,
  });


  const [state,setState] = useState(false)


  const [mutation, { isLoading, data: uploadResponse, error }] =
    useContractorMutation();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      name: "",
      number: "",
      message: "",
      image: null,
    },
  });

  useEffect(() => {
    if (error) {
      toast.warn(error?.data?.message);
      reset()
      setValue("image", null);
      setFormState(prev => ({ ...prev, image: null }));
      
    }
    if (uploadResponse) {
      toast.success(uploadResponse?.message);
      reset()
      setValue("image", null);    
      setFormState(prev => ({ ...prev, image: null }));
      
    }
  }, [mutation, uploadResponse, error]);

  const onSubmit = async (data: FormValues) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("number", data.number);
    formData.append("message", data.message);
    if (data.image) {
      formData.append("image", data.image);
    }  
      await mutation(formData).unwrap();     
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setValue("image", file); // update React Hook Form value
    setFormState((prevState) => ({
      ...prevState,
      image: file,
    }));
  };

  const triggerFileInput = () => {
    const fileInput = document.getElementById("image") as HTMLInputElement;
    fileInput?.click();
  };

  return (
    <section className="relative  flex flex-col gap-6 md:gap-10">
      {isLoading && (
  <div className="fixed inset-0 bg-black bg-opacity-25 flex justify-center items-center z-50">
    <div className="bg-white p-6 flex items-center rounded-lg shadow-lg">
      Loading...
    </div>
  </div>
)}


      <div className="p-[12px] md:p-[25px] lg:p-[40px] flex flex-col gap-6 md:gap-10">
        <div className="py-[24px] px-[24px] md:py-8 md:px-8 flex flex-col sm:flex-row gap-6 sm:gap-0 sm:justify-between bg-[#F2F2F2] rounded-[12px]">
          <div className="font-[700] text-[24px] md:text-[32px] leading-[35px] md:leading-[50px] lg:w-[600px]">
            Looking For help? Here are Our most Frequently asked Questions.
          </div>

          <Link to={"/faq"} className="bg-white sm:bg-turkishBlue-default py-[16px] px-[14px] inline-flex justify-center h-[56px] rounded-lg gap-[8px] items-center">
            <span className="text-black sm:text-white font-[400] text-[16px] sm:text-[20px]">
              Query
            </span>
            <MdOutlineKeyboardArrowRight className="bg-turkishBlue-default sm:bg-white text-white sm:text-turkishBlue-default " />
          </Link>
        </div>

        <div className="flex flex-col gap-[16px] sm:gap-[24px] w-full">
          <span className="font-[700] text-[24px] leading-[29px]">
            Query Form
          </span>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="px-[20px] md:px-[40px] flex flex-col py-[36px] gap-[30px] rounded-[12px] w-full border md:border-none md:bg-[#FAFAFA]"
          >
            <div className="flex flex-col md:flex-row gap-[24px] md:gap-[36px] w-full">
              <div className="flex flex-col gap-[12px] w-full">
                <label className="font-[600] text-[16px] leading-[19px]">
                  Name
                </label>
                <div className="px-[14px] py-[18px] border bg-white rounded-md">
                  <input
                    type="text"
                    id="name"
                    placeholder="Enter Name..."
                    className="border-none outline-none"
                    {...register("name", {
                      required: "Name is required",
                      minLength: {
                        value: 2,
                        message: "Name must be at least 2 characters long",
                      },
                      maxLength: {
                        value: 50,
                        message: "Name has to no longer then 50 characters",
                      },
                      pattern: {
                        value: /^[A-Za-z\s]+$/i,
                        message: "Name can only contain letters and spaces",
                      },
                    })}
                    onChange={handleFieldChange}
                    value={formState.name}
                  />
                </div>
                {errors.name && (
                  <p className="text-red-500">
                    {(errors.name as FieldError).message}
                  </p>
                )}
              </div>

              <div className="flex flex-col gap-[12px] w-full">
                <label className="font-[600] text-[16px] leading-[19px]">
                  Contact Number
                </label>
                <div className="px-[14px] py-[18px] border bg-white rounded-md">
                  <input
                    type="text"
                    id="number"
                    placeholder="Enter Contact Number..."
                    className="border-none outline-none"
                    {...register("number", {
                      required: "Number is required",
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Please enter a valid 10-digit phone number",
                      },
                    })}
                    onChange={handleFieldChange}
                    value={formState.number}
                  />
                </div>
                {errors.number && (
                  <p className="text-red-500">
                    {(errors.number as FieldError).message}
                  </p>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-[12px] w-full">
              <label className="font-[600] text-[16px] leading-[19px]">
                Query
              </label>
              <div className="px-[14px] py-[18px] border rounded-lg bg-white">
                <textarea
                  id="message"
                  className="w-full h-full rounded-md px-3 py-1 font-[400] border-none outline-none"
                  rows={4}
                  placeholder="Write Your Message...."
                  {...register("message", {
                    required: "Query is required",
                    minLength: {
                      value: 10,
                      message: "Query must be at least 10 characters long",
                    },
                    maxLength: {
                      value: 500,
                      message: "Query cannot exceed 500 characters",
                    },
                  })}
                  onChange={handleFieldChange}
                  value={formState.message}
                ></textarea>
              </div>
              {errors.message && (
                <p className="text-red-500">{errors.message.message}</p>
              )}
            </div>

            <div className="p-[24px] flex flex-col gap-[26px] ">
              <span className="font-[600] text-[16px] leading-[19px]">
                Upload Photo
              </span>

              <div className="w-full bg-white py-[30px] border-2 border-[#797979] border-dashed rounded-[12px] flex flex-col justify-center items-center">
                <div className="flex flex-col gap-[6px] items-center justify-center text-center">
                  <div>
                    <RiUploadCloud2Line className="w-[40px] h-[40px] text-[#004EBA]" />
                  </div>

                  <div className="font-[300] text-[14px] leading-[7px] text-[#9CA3AF]">
                    Upload Your Files
                  </div>
                  <div
                    className="text-[#004EBA] font-[400] text-[16px] leading-[24px] underline cursor-pointer"
                    onClick={triggerFileInput} // Trigger the file input
                  >
                    Browse
                  </div>
                  <input
                    type="file"
                    id="image"
                    accept="image/*"
                    {...register("image")}
                    onChange={handleFileChange}
                    className="hidden"
                  />
                </div>
                {/* Show uploaded file details */}
                {formState.image && (
                  <div className="mt-4 text-center">
                    <p className="text-gray-600 font-[500] text-[14px] leading-[24px]">
                      Selected File: {formState.image.name}
                    </p>
                    <p className="text-gray-500 text-[12px]">
                      Size: {(formState.image.size / 1024).toFixed(2)} KB
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="flex justify-between">
              <button
                type="button"
                onClick={() =>{
                   reset(); 
                   setFormState(prev => ({ ...prev, image: null }));
                   setValue("image", null);
                }}

                className="px-[40px] py-[16px] font-[700] text-[16px] leading-[24px] text-black bg-[#F2F2F2] rounded-[12px]"
              >
                Clear All
              </button>

              <button
                type="submit"
                className="px-[40px] py-[16px] font-[700] text-[16px] leading-[24px] text-white bg-turkishBlue-default rounded-[12px]"
              >
                Submit
              </button>
            </div>
          </form>
        </div>

      </div>
    </section>
  );
};
