import { useClaimRewardMutation, useGetRewardsQuery } from "../../../Redux/Api/Admin/Reward";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { PointsHeader } from "./pointsHeader";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { ConfirmModal } from "../../../utils/CustomModal";
import ProfileRewardCard from "./ProfileRewardCard";
import ProfilePagesLoading from "./ProfilePagesLoading";
import ProfileNoData from "./ProfileNoData";

interface Reward {
	_id: string;
	name: string;
	image: string;
	rewardValue: number;
	claimPendingStatus: boolean;
}

export const Rewards = () => {
	const [claimReward, { isSuccess, isError, error }] = useClaimRewardMutation();
	const { data: rewardsData, isFetching } = useGetRewardsQuery();
	const [selectedRewardId, setSelectedRewardId] = useState<string | null>(null);
	const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const userDetails = useSelector((state: RootState) => state.Login.ContractorData);

	const handleClaimReward = async (rewardId: string) => {
		try {
			await claimReward({ rewardId }).unwrap();
		} catch (error: any) {
			console.error("Error claiming reward:", error);
		}
	};

	useEffect(() => {
		if (isSuccess) {
			toast.success("Reward claimed successfully!");
		}
		if (isError) {
			toast.error(error?.data?.message || "An error occurred. Please try again.");
		}
	}, [isSuccess, isError]);

	useEffect(() => {
		if (isFetching) setIsLoading(true)
		else setIsLoading(false)
	}, [isFetching])

	const openPopup = (rewardId: string) => {
		setSelectedRewardId(rewardId);
		setIsPopupOpen(true);
	};

	const closePopup = () => {
		setSelectedRewardId(null);
		setIsPopupOpen(false);
	};

	const handleConfirmClaim = () => {
		if (selectedRewardId) {
			handleClaimReward(selectedRewardId);
		}
		closePopup();
	};

	return (
		<section className="font-inter">
			{userDetails?.isVerified && <PointsHeader />}
			<div className="profile-container pb-10">
				<h1 className="font-semibold text-3xl leading-[43px]">Rewards</h1>
				<div className="flex flex-col sm:flex-row flex-wrap justify-center items-center gap-10 sm:gap-14 mt-5">
					{
						isLoading ? (
							<ProfilePagesLoading />
						) : rewardsData?.response?.length > 0 ? (
							rewardsData.response.map((reward: Reward) => (
								<ProfileRewardCard
									key={reward._id}
									{...reward}
									openPopup={openPopup}
								/>
							))
						) : (
							<ProfileNoData text="No rewards available !" />
						)
					}
				</div>
			</div>
			<ConfirmModal
				isOpen={isPopupOpen}
				handleClose={closePopup}
				handleConfirm={handleConfirmClaim}
				text="Do you really want to claim this reward?"
				buttonText="Yes"
			/>
		</section>
	);
};
