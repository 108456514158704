import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { RiFileExcel2Fill } from "react-icons/ri";
import { usePublishedContractorMutation } from "../../../Redux/Api/Contractor";
import { useDeleteRewardMutation, useEditRewardMutation, useGetAdminRewardsQuery } from "../../../Redux/Api/Admin/Reward";
import { Rewards } from "../../../Redux/Api/types";
import { Coupons } from "./components/Coupons";
import { RewardChange } from "./components/Filter/RewardChange";
import { MdDelete } from "react-icons/md";
import { UploadImage } from "./components/uploadImage";
import { LiaEditSolid } from "react-icons/lia";
import TableLoading from "../../../common/admin-common/TableLoading";
import TableNoData from "../../../common/admin-common/TableNoData";
import { exportToExcel } from "../../../utils/exportToExcel";
import ConfirmModel from "../../../utils/ConfirmModel";
import { toast } from "react-toastify";

interface RewardStates {
  [key: number]: string;
}

export const Reward = () => {
  const [fetchContractors, { isLoading }] = usePublishedContractorMutation();
  const [editReward] = useEditRewardMutation();
  const [deleteRewardMutation, { isSuccess: deleteRewardSuccess, deleteRewardError }] = useDeleteRewardMutation();
  const [imageId, setImageId] = useState<any>("");
  const { data: Reward } = useGetAdminRewardsQuery();
  const [isCouponVisible, setIsCouponVisible] = useState<boolean>(false);
  const [isImageUpload, setIsImageUpload] = useState<boolean>(false);
  const [rewardStates, setRewardStates] = useState<RewardStates>({});
  const [editableNameIndex, setEditableNameIndex] = useState<number | null>(null);
  const [newName, setNewName] = useState<string>("");
  const [search, setSearch] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const handleDeleteClick = (id: string): void => {
    setSelectedItemId(id);
    setIsModalOpen(true);
  };

  const handleCloseModal = (): void => {
    setIsModalOpen(false);
    setSelectedItemId(null);
  };

  const handleConfirm = async (isConfirmed: boolean) => {
    if (isConfirmed && selectedItemId !== null) {
      await deleteReward(selectedItemId);
    }
    handleCloseModal();
  };

  const handleShowCoupon = (): void => setIsCouponVisible(true);
  const handleCloseCoupon = (): void => setIsCouponVisible(false)
  const handleCloseImageUpload = (): void => setIsImageUpload(false)

  const handleRewardChange = (rowIndex: number, grade: string) => {
    setRewardStates((prevState: RewardStates) => ({
      ...prevState,
      [rowIndex]: grade,
    }));
  };

  const handleCloseRewardChange = (rowIndex: number) => {
    setRewardStates((prevState: RewardStates) => ({
      ...prevState,
      [rowIndex]: "",
    }));
  };

  // Handle Reward Status

  const handleStatus = async (status: boolean, id: string) => {
    const body = { status };
    try {
      await editReward({ id, body }).unwrap();
      toast.success("Reward status updated successfully");
    } catch (error) {
      console.error(`Failed to update reward status for ID ${id}:`, error);
      toast.error("Failed to update reward status");
    }
  };

  // Handle Fetch Contrators

  const DEFAULT_LIMIT = "20";
  const DEFAULT_PAGE = 1;

  const fetchContractorsData = useCallback(async () => {
    try {
      await fetchContractors({ limit: DEFAULT_LIMIT, page: DEFAULT_PAGE });
    } catch (error) {
      console.error("Error fetching contractors:", error);
    }
  }, [fetchContractors]);

  useEffect(() => {
    fetchContractorsData();
  }, [fetchContractorsData]);

  // Handle Delete Reward

  const deleteReward = async (id: string) => {
    try {
      await deleteRewardMutation(id).unwrap();
    } catch (error) {
      console.error("Error deleting reward:", error);
    }
  };

  useEffect(() => {
    if (deleteRewardSuccess) {
      toast.success("Reward deleted successfully");
    }
    if (deleteRewardError) {
      toast.error("Failed to delete reward");
    }
  }, [deleteRewardSuccess, deleteRewardSuccess]);

  // Handle Edit Reward Name

  const handleNameClick = (index: number, currentName: string) => {
    setEditableNameIndex(index);
    setNewName(currentName);
  };

  const saveName = async (id: string) => {
    if (!newName.trim()) {
      toast.error("Name cannot be empty");
      return;
    }
    const body = { name: newName.trim() };
    try {
      await editReward({ id, body }).unwrap();
      toast.success("Reward name updated successfully!");
    } catch (error) {
      console.error("Error updating reward name:", error);
      toast.error("Error updating reward name");
    } finally {
      setEditableNameIndex(null);
      setNewName("");
    }
  };



  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => setNewName(e.target.value);
  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value.toLowerCase());

  const filtersData = Reward?.response?.filter((item: any) => item?.name?.toLowerCase().includes(search))

  const resetAll = (): void => setSearch("");


  const exportData = () => {
    const filteredData = (filtersData || []).map((item: any) => ({
      "Coupon Name": item?.name,
      "Grade A": item?.points?.rewardA,
      "Grade B": item?.points?.rewardB,
      "Grade C": item?.points?.rewardC,
      "Status": item.status ? "Active" : "Inactive",
    }));

    exportToExcel(filteredData, "RewardData");
  };

  return (

    <div className="w-full h-auto px-3 mt-3 pb-3">



      <div className="w-full   bg-white rounded-t-xl mb-4 pt-2 z-[-1px]">
        {/* Filters Start */}
        <div className="flex justify-between py-2 px-4">
          <div className="flex items-center gap-2">
            <input
              type="text"
              onChange={handleSearchChange}
              value={search}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-[0.5px] focus:border-blue-500 block w-full py-2 px-3"
              placeholder="Search.."
              style={{
                boxShadow:
                  "0px 0px 7.6px 0px #00000040 inset",
              }}
            />
          </div>
          <div className="flex gap-2">
            <button
              onClick={resetAll}
              className="flex gap-1 items-center text-sm py-2 px-3 border border-red-700 text-red-700 rounded-xl bg-red-100 hover:text-white  hover:bg-red-500 duration-150 ">
              Reset
            </button>
            <button
              onClick={handleShowCoupon}
              className="flex gap-1 items-center text-sm py-2 px-3 border border-blue-700 text-blue-700 rounded-xl bg-blue-100 hover:text-white  hover:bg-blue-700 duration-150 ">
              Add Coupons
            </button>
            <button
              onClick={exportData}
              className="flex gap-1 items-center text-sm py-2 px-3 border border-[#00CB4A] text-[#00CB4A] rounded-xl bg-green-100 hover:text-white  hover:bg-green-500 duration-150 ">
              Excel{" "}
              <RiFileExcel2Fill className="w-[20px] h-[20px]" />
            </button>
          </div>
        </div>
        {/* Filters End */}

        {/* Add Coupon Modal */}
        {isCouponVisible && <Coupons onClose={handleCloseCoupon} />}

        {/* Table Start */}
        <div className="w-full overflow-x-auto px-3 pb-32 ">
          <table className="table-auto min-w-full divide-y divide-gray-200 mt-2 relative">
            <thead className="bg-white divide-y divide-gray-200">
              <tr className="border-t border-b border-gray-300">
                {[
                  { label: "Images", width: "w-[220px]" },
                  { label: "Coupon Name" },
                  { label: "Reward", className: "whitespace-nowrap" },
                  { label: "Status" },
                  { label: "Action" },
                ].map((header, index) => (
                  <th
                    key={index}
                    className={`px-6 py-3 text-center text-sm font-inter text-[#1E293B] ${header.className || ""
                      } ${header.width || ""}`}
                  >
                    {header.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white ">
              {
                isLoading ? <tr>
                  <TableLoading colCount={5} />
                </tr> : !isLoading && filtersData?.length > 0 ? filtersData?.map(
                  (i: Rewards, index: number) => (
                    <tr
                      key={index}
                      className="shadow-md mb-6 space-y-4 rounded-xl   ">
                      <td className="p-3 w-[240px] relative">
                        <div>
                          <img
                            src={i?.image}
                            className="rounded-md h-[140px] w-[190px] object-cover"
                            alt={i?.name}
                          />

                        </div>
                        <button onClick={() => {
                          setIsImageUpload(true);
                          setImageId(i?._id);
                        }} className="absolute bottom-3 right-3 text-xl"><LiaEditSolid /></button>
                      </td>
                      <td className=" whitespace-nowrap font-inter text-sm text-[#6E7079]  transition-all duration-300 ease-in-out">
                        {editableNameIndex === index ? (
                          <div className="h-full flex flex-col justify-center items-center gap-1">
                            <input
                              value={newName}
                              onChange={
                                handleNameChange
                              }
                              className="max-w-[120px] border px-3 py-1 border-gray-300 outline-none  rounded-lg"
                            />
                            <span
                              className="text-white font-bold px-3 text-center text-sm py-1 bg-[#334155] rounded-md w-[120px] cursor-pointer transition-all duration-300"
                              onClick={() =>
                                saveName(i._id)
                              }>
                              Save
                            </span>
                          </div>
                        ) : (
                          <span className="flex gap-1 items-center justify-center transition-all duration-300 ">
                            <span className="text-[#939393]">{i?.name}</span>{" "}
                            <LiaEditSolid
                              className="text-[#334155] cursor-pointer transition-transform duration-300"
                              onClick={() =>
                                handleNameClick(
                                  index,
                                  i?.name
                                )
                              }
                            />
                          </span>
                        )}
                      </td>
                      <td className=" whitespace-nowrap font-inter text-sm text-[#6E7079]">
                        <div className="relative">
                          <div className="flex gap-[12px]">
                            <div className="flex flex-col ">
                              <span className="text-[#939393] font-medium">
                                Grade A
                              </span>
                              <span className="text-amber-500 flex gap-[23px] items-center relative">
                                {
                                  i?.points
                                    ?.rewardA
                                }{" "}
                                <LiaEditSolid
                                  className="cursor-pointer text-black"
                                  onClick={() =>
                                    handleRewardChange(
                                      index,
                                      "A"
                                    )
                                  }
                                />
                                {rewardStates[
                                  index
                                ] === "A" && (
                                    <div
                                      className="absolute top-[20px] left-[-60px] z-20 "
                                      style={{
                                        transform:
                                          "translateX(-50%)",
                                      }}>
                                      <RewardChange
                                        grade={
                                          "a"
                                        }
                                        id={
                                          i?._id
                                        }
                                        handleClose={() =>
                                          handleCloseRewardChange(
                                            index
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                              </span>
                            </div>
                            <div className="flex flex-col">
                              <span className="text-[#939393] font-medium">
                                Grade B
                              </span>
                              <span className="text-amber-500 flex gap-[23px] items-center relative">
                                {
                                  i?.points
                                    ?.rewardB
                                }{" "}
                                <LiaEditSolid
                                  className="cursor-pointer text-black"
                                  onClick={() =>
                                    handleRewardChange(
                                      index,
                                      "B"
                                    )
                                  }
                                />
                                {rewardStates[
                                  index
                                ] === "B" && (
                                    <div
                                      className="absolute top-[20px] left-[-60px]  z-20 "
                                      style={{
                                        transform:
                                          "translateX(-50%)",
                                      }}>
                                      <RewardChange
                                        grade={
                                          "b"
                                        }
                                        id={
                                          i?._id
                                        }
                                        handleClose={() =>
                                          handleCloseRewardChange(
                                            index
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                              </span>
                            </div>
                            <div className="flex flex-col">
                              <span className="text-[#939393] font-medium">
                                Grade C
                              </span>
                              <span className="text-amber-500 flex gap-[23px] items-center relative">
                                {
                                  i?.points
                                    ?.rewardC
                                }{" "}
                                <LiaEditSolid
                                  className="cursor-pointer text-black"
                                  onClick={() =>
                                    handleRewardChange(
                                      index,
                                      "C"
                                    )
                                  }
                                />
                                {rewardStates[
                                  index
                                ] === "C" && (
                                    <div
                                      className="absolute top-[20px] left-[-60px]  z-20 "
                                      style={{
                                        transform:
                                          "translateX(-50%)",
                                      }}>
                                      <RewardChange
                                        grade={
                                          "c"
                                        }
                                        id={
                                          i?._id
                                        }
                                        handleClose={() =>
                                          handleCloseRewardChange(
                                            index
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="font-inter text-sm text-[#6E7079]">
                        <span
                          className={`${i.status
                            ? "bg-green-100 border-green-600 text-green-600 hover:bg-green-600 hover:text-white"
                            : "bg-red-100 border-red-600 text-red-600 hover:bg-red-600 hover:text-white"
                            } border  px-3 py-2  cursor-pointer text-[12px] leading-[14px] rounded-xl`}
                          onClick={() =>
                            handleStatus(
                              !i?.status,
                              i?._id
                            )
                          }>
                          {i.status
                            ? "Active"
                            : "Inactive"}
                        </span>
                      </td>
                      <td className=" font-inter text-2xl  w-[10%] cursor-pointer text-red-600">
                        <span
                          onClick={() => handleDeleteClick(i?._id)}
                        >
                          <MdDelete />

                        </span>
                      </td>
                    </tr>
                  )
                ) : <TableNoData colCount={5} text={"Data Not Available"} />
              }

            </tbody>
          </table>
        </div>

        {/* Upload Image Modal */}
        {isImageUpload && (
          <UploadImage
            onClose={handleCloseImageUpload}
            id={imageId}
          />
        )}

        {/* Confirm Delete */}
        <ConfirmModel
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onConfirm={handleConfirm}
        />
      </div>
    </div>

  );
};

