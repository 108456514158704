import NoResult from "./Noresult";
import { useNavigate } from "react-router-dom";
import { SearchBoxProps, SearchQuery } from "./types";
import { useDispatch } from "react-redux";
import { clearSubResults, setSubSearch } from "../../Redux/reducer/Filter";
import { MdOutlineSearch } from "react-icons/md";
import { GoArrowUpLeft } from "react-icons/go";
import { HiMiniArrowTrendingUp } from "react-icons/hi2";

const SearchBox = ({ searchResults, isLoading, close, search, onGlassClick, queryData, handleTopSearchesClick }: SearchBoxProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (name: string) => {
    navigate("/subItems");
    dispatch(clearSubResults());
    dispatch(setSubSearch(name));
    close();
  };

  const handleItemClick = (index: number, name: string) => {
    if ([0, 1, 2].includes(index)) {
      onGlassClick();
    } else if (index >= 3) {
      handleClick(name);
    }
  };

  const renderIcon = (index: number) => {
    return index >= 3 ? (
      <GoArrowUpLeft fontSize="1.10rem" color="black" />
    ) : (
      <MdOutlineSearch fontSize="1.10rem" color="#5097A4" />
    );
  };

  const highlightSearchTerm = (text: string, searchTerm: string) => {
    const regex = new RegExp(`(${searchTerm})`, "gi");
    let matchCount = 0;

    return text.split(regex).map((part, index) => {
      if (part.toLowerCase() === searchTerm.toLowerCase() && matchCount < 3) {
        matchCount++;
        return (
          <span key={index} className="bg-[#5097A4] text-white">
            {part}
          </span>
        );
      }
      return <span key={index} className="text-black">{part}</span>;
    });
  };



  return (
    <>
      <div className="z-50 bg-white w-full flex flex-col gap-4 border px-4 py-2">
        <div className="w-full flex flex-col gap-4">
          <div className="w-full h-40 overflow-auto">
            <div className="w-full flex flex-col overflow-y-auto font-bold text-white">
              {!search && !isLoading && (
                <>
                  <div
                    className=" cursor-pointer text-xs  py-1  mr-3 mb-[3px]"
                    onClick={onGlassClick}>
                    <div className="flex items-center justify-between">
                      <span className="font-inter font-medium text-sm leading-4 text-wrap text-gray-900 flex gap-2 items-center" >
                         Top Searches <HiMiniArrowTrendingUp fontSize={'1.10rem'}/> 
                      </span>
                    </div>
                  </div>

                  <div className="space-y-1">
                    {
                      queryData && queryData?.map((searchItem: SearchQuery, i: number) => (
                        <div
                          className="hover:bg-gray-100 cursor-pointer text-xs px-2 py-1 rounded-sm mr-3 border-[0.3px] hover:scale-100 mb-[2px]"
                          onClick={() => handleTopSearchesClick(searchItem.query || "")}>
                          <div className="flex items-center gap-4">
                            <MdOutlineSearch
                              fontSize="1.10rem"
                              color="#5097A4"
                            />
                            <div className="flex-1">
                              <span className="font-inter font-normal leading-5 tracking-wider">
                                <span className="   text-gray-800 tracking-wider">
                                  {searchItem.query}
                                </span>
                              </span>
                            </div>

                          </div>
                        </div>
                      ))
                    }
                  </div>
                </>
              )}

              {search && (
                <div
                  className="hover:bg-gray-100 cursor-pointer text-xs px-2 py-1  mr-3 border-[0.4px] mb-[2px]"
                  onClick={onGlassClick}>
                  <div className="flex items-center gap-4">
                    <MdOutlineSearch
                      fontSize="1.10rem"
                      color="#5097A4"
                    />
                    <div className="flex-1">
                      <span className="font-inter font-normal leading-5 tracking-wider">
                        <span className=" font-bold  bg-[#5097A4] text-white tracking-wider">
                          {search}
                        </span>
                      </span>
                    </div>

                  </div>
                </div>
              )}

              {search && searchResults?.length ? (
                <ul className="space-y-1">
                  {searchResults.map(
                    (searchResult, index) => (
                      <div
                        key={index}
                        className="hover:bg-gray-100 cursor-pointer text-xs px-2 py-1 rounded-sm border-[0.4px]  transition-all duration-200"
                        onClick={() =>
                          handleItemClick(
                            index,
                            searchResult?.name
                          )
                        }>
                        <div className="flex items-center gap-4">
                          {renderIcon(index)}
                          <div className="flex-1">
                            <span className="font-inter font-normal text-xs leading-5 tracking-wide">
                              {[0, 1, 2].includes(
                                index
                              )
                                ? highlightSearchTerm(
                                  searchResult.itemName ||
                                  "",
                                  search
                                )
                                :
                                <span className="text-black">{searchResult.name
                                }</span>
                              }
                            </span>
                          </div>

                        </div>
                      </div>
                    )
                  )}
                </ul>
              ) : search && isLoading ? (
                <div className="flex items-center justify-center h-full pt-10">
                  <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
                </div>
              ) : (
                search && <NoResult search={search} searchResults={searchResults || []} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBox;
