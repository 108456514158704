import defaultImage from "../../../assets/assets/dummyProduct.png";
import bgImage from "../../../assets/assets/reward-effect.png";
import { toast } from "react-toastify";
import { useReward } from "react-rewards";
interface Props {
    id: string;
    image?: string;
    name: string;
    itemCount: number;
    onNavigate: () => void;

}
const AssignedCategoryCard = ({
    image,
    name,
    itemCount,
    id,
    onNavigate,
}: Props) => {
    const { reward, isAnimating } = useReward(`reward-${id}`, "confetti");
    const handleNavigate = () => {
        if (itemCount > 0) {
            onNavigate();
        } else {
            toast.warn("Category has no items yet!");
        }
    };



    const handleMouseEnter = () => {
        if (!isAnimating) reward();
    };



    return (

        <div
            onClick={handleNavigate}
            onMouseEnter={handleMouseEnter}

            className="relative cursor-pointer bg-[#F0FDFF] border-[3px] hover:bg-[#F7D000] hover:border-white group border-[#BFEBF3] rounded-xl shadow-md transform transition duration-500 hover:scale-105 space-y-4 pb-6 pt-4 px-3 ">
            <div
                className="p-2 flex justify-center"
                style={{
                    backgroundImage: `url(${bgImage})`,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: 'no-repeat'
                }}>
                <a href="#">
                    <img
                        className="rounded-md w-11/12 h-[170px] object-contain"
                        src={image || defaultImage}
                        loading="lazy"
                    />
                </a>
            </div>
            <div className="px-4 pb-3 flex flex-col items-center mt-4">
                <div className="flex flex-col items-center" id={`reward-${id}`} >
                    <h5 id={`reward-${id}`} className="text-xl font-semibold tracking-tight mx-auto    text-[#5DA4B1]  group-hover:text-white">
                        {name?.length > 14
                            ? `${name.slice(0, 14)}...`
                            : name}
                    </h5>

                    <p className="antialiased text-[#5DA4B1] text-sm break-all flex mt-2 justify-center group-hover:text-white ">
                        <span className="font-semibold">
                            Item Count :{" "}
                        </span>{" "}
                        {itemCount}
                    </p>
                </div>
            </div>
        </div>

    );
};

export default AssignedCategoryCard;