import { Link } from "react-router-dom";
import img1 from "../assets/Ellipse 1937.png";
import img2 from "../assets/Ellipse 1938.png";
import img3 from "../assets/Ellipse 1939.png";

import reward from "../assets/rewardassgin.png";
const NoLoginAssignedRewards = () => {
    return (
        <div className="container mx-auto h-auto lg:p-3">
            <div className="w-full grid grid-cols-1 lg:grid-cols-2">
                <div className="w-full relative ">
                    <div className="relative mt-4">
                        <img
                            src={reward}
                            alt=""
                            className="p-4 lg:p-0 relative z-30 w-full"
                        />
                        <img
                            src={img2}
                            alt=""
                            className="hidden lg:block absolute bottom-[-15px] left-0 z-10 w-44 xl:w-auto"
                        />
                        <img
                            src={img3}
                            alt=""
                            className="hidden lg:block absolute bottom-0 right-0 z-20 w-44 xl:w-auto"
                        />
                    </div>
                </div>
                <div className="w-full h-[200px] relative lg:h-auto">
                    <img
                        src={img1}
                        alt=""
                        className="relative top-[-51.5px] z-[-1px] hidden lg:block"
                    />
                    <div className="w-[95%] mx-auto p-3 lg:absolute top-[100px] xl:top-[150px] right-3 text-lg lg:text-xl xl:text-2xl lg:text-end lg:pr-4 2xl:right-14 font-bold lg:text-white">
                        <p className="">
                            Join with your account to unlock
                        </p>{" "}
                        <p>the rewards page. Please sign up or</p>{" "}
                        <p className="mb-5">log in to continue.</p>
                        <Link
                            to="/signup"
                            className="bg-[#FEBD69] text-black rounded-lg underline py-2 px-3 text-[14px]">
                            Signup / Login{" "}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoLoginAssignedRewards