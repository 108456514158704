export function formatDateTime(dateString: string): string {
	const date = new Date(dateString);
	const month = (date.getMonth() + 1).toString().padStart(2, "0");
	const day = date.getDate().toString().padStart(2, "0");
	const year = date.getFullYear();

	let hours = date.getHours();
	const minutes = date.getMinutes().toString().padStart(2, "0");
	const amPm = hours >= 12 ? "PM" : "AM";
	hours = hours % 12 || 12;
	return `${month}-${day}-${year} ${hours
		.toString()
		.padStart(2, "0")}:${minutes} ${amPm}`;
}

export const formatDate = (date: Date) => {
	const day = date.getDate().toString().padStart(2, "0");
	const month = (date.getMonth() + 1).toString().padStart(2, "0");
	const year = date.getFullYear();
	return `${day}/${month}/${year}`;
};
