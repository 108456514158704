import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Item, ItemData, Status } from "./types";
import { useOrderTrackerMutation } from "../../Redux/Api/Order";
import { toast } from "react-toastify";
import { chawlaispat } from "../../Redux/apiRoutes";
import usePageMeta from "../../common/hooks/usePageMeta";
import OrderDetailsComponent from "./OrderDetailsComponent";
import ProcessingOrder from "./ProcessingOrder";


export const TrackYourOrder = () => {
  const params = useParams<{ name?: string }>();
  const name = params.name || "";
  const decodedName = decodeURIComponent(name);


  const [mutation, { data, error }] = useOrderTrackerMutation();

  const location = useLocation();
  useEffect(() => {
    if (decodedName) {
      mutation(decodedName);
    }
  }, [decodedName, mutation]);


  useEffect(() => {
    if (data?.success) {
      setItemData(data);
    } else if (data && !data?.success) {
      toast.error("Invalid order number. Please enter a valid tracking number.");
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      console.error(error);
      toast.error("There was an error processing your request. Please try again.");
    }
  }, [error]);

  useEffect(() => {
    document.title = "Order Tracking Page - Chawla Ispat";
  }, []);

  const [itemData, setItemData] = useState<ItemData | null>(null);

  const title = "Order Tracking | Building Material Supplier | Chawla Ispat";
  const description = "Track your orders and view status updates on the Tracking Page of Chawla Ispat.";
  const canonicalUrl = chawlaispat;

  usePageMeta(title, description, canonicalUrl);

  const salesOrderData = itemData?.salesOrder?.salesOrderData
  const lengthItem = itemData?.salesOrder?.items?.length
  const items: Item[] = itemData?.salesOrder?.items || []

  return (
    <>
      <div className="w-full max-w-[1200px] mx-auto mt-4 px-2 lg:px-5 mb-6">
        <OrderDetailsComponent salesOrderData={salesOrderData} decodedName={decodedName} lengthItem={lengthItem} />
        <ProcessingOrder items={items} />
      </div>
    </>
  );
};

