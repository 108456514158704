import React from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { ScaleLoader } from "react-spinners";
import useCategoryNames from "../../../common/hooks/useCategoryName";
import useCompanyNames from "../../../common/hooks/useCompanyName";
import { RxCross1 } from "react-icons/rx";
import { clearCategory, clearCompany } from "../../../Redux/reducer/Filter";

interface DetailsBarProps {
  total?: number;
  isLoading?: boolean;
  onClick?: () => void;
  limit: number;
  hasResults: boolean;
}

export const DetailsBar: React.FC<DetailsBarProps> = ({
  total = 0,
  isLoading ,
  onClick = () => {},
  limit,
  hasResults
}) => {

  const categoryBox = useSelector((state: RootState) => state.selection.selectedCategories);
  const companyBox = useSelector((state: RootState) => state.selection.selectedCompanies);
  const search = useSelector((state: RootState) => state.selection.selectedSearch);
  const dispatch = useDispatch();

  const categoryNames = useCategoryNames(categoryBox);
  const companyNames = useCompanyNames(companyBox);

  return (
    <> 
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-20">
          <ScaleLoader color="black" height={30} margin={4} radius={2} width={4} />
        </div>
      )}
      
      {!isLoading && (
        <div className=" flex font-[400] text-[12px] md:text-[14px] leading-[12.52px] md:leading-[16px] justify-between px-[20px] py-[8px] border-b shadow-lg sticky z-10 bg-white">
          <span className="flex w-full items-center gap-9 whitespace-nowrap">
            <div>
              {hasResults && (
                <h2 className="flex gap-2">
                  {`${limit || ""} of over ${total} results${search ? ` for ` : ``}`}
                  <span className="text-[#C45500]">
                    {search ? `"${search.toUpperCase()}"` : ``}
                  </span>
                </h2>
              )}
            </div>

            {categoryBox && categoryBox.length > 0 && (
              <div className="md:flex hidden items-center gap-1">
                <span className="font-semibold whitespace-nowrap">Category :</span>
                <span className="flex gap-1">
                  {categoryNames.map((category) => (
                    <span
                      key={category?.id}
                      className="px-[7px] flex items-center gap-2 py-[4px] bg-[#5097A4] h-[30px] text-white border rounded-[6px] font-[300] text-[11px] leading-[20px]"
                    >
                      <span>{category?.name}</span>
                      <RxCross1
                        className="cursor-pointer"
                        onClick={() => dispatch(clearCategory(category?.id))}
                        aria-label={`Remove ${category?.name} category`}
                      />
                    </span>
                  ))}
                </span>
              </div>
            )}

            {companyBox && companyBox.length > 0 && (
              <div className="md:flex hidden items-center gap-1">
                <span className="font-semibold whitespace-nowrap">Company :</span>
                <span className="flex gap-1">
                  {companyNames.map((company) => (
                    <span
                      key={company?.id}
                      className="px-[7px] flex items-center gap-2 py-[4px] bg-[#5097A4] h-[30px] text-white border rounded-[6px] font-[300] text-[11px] leading-[20px]"
                    >
                      <span>{company?.name}</span>
                      <RxCross1
                        className="cursor-pointer"
                        onClick={() => dispatch(clearCompany(company?.id))}
                        aria-label={`Remove ${company?.name} company`}
                      />
                    </span>
                  ))}
                </span>
              </div>
            )}
          </span>
          <div
            className="flex sm:hidden px-[5px] py-[3px] gap-[4px] border-l cursor-pointer"
            onClick={onClick}
            role="button"
            aria-label="Open filter"
          >
            <span className="font-[500] text-[12px] leading-[14.52px] text-[#5097A4]">Filter</span>
            <RiArrowDownSLine />
          </div>
        </div>
      )}
    </>
  );
};