import { useNavigate } from "react-router-dom";
import icon from "../../../assets/Icon.png";
import whatsapp from "../../../assets/whatsappicon.svg";
import item from "../../../assets/item.png";
import Shield from "../../../assets/Shield.png";
import Verification from "../../../assets/Verification.png";
import Unchecked from "../../../assets/Unchecked.png";

interface AssignedCategoryItemCardProps {
	product: {
		id: string;
		productName: string;
		finalPrice: number;
		rewards: number;
		status: boolean;
		unit: string;
		images: string;
		companyName: string;
	};
}

const AssignedCategoryItemCard: React.FC<AssignedCategoryItemCardProps> = ({ product }) => {
	const navigate = useNavigate();

	const handleWhatsappClick = () => {
		const phoneNumber = "919258294692";
		const url = `https://wa.me/${phoneNumber}`;
		window.open(url, "_blank");
	};

	return (
		<div className="flex gap-[2rem] py-3 px-3  w-full rounded-sm col-span-2 2xl:col-span-1 shadow-md border"
			>
			<div className="flex gap-3 sm:gap-[2rem]  w-full">
				<div
					onClick={() =>
						navigate(`/singleproduct/${product.id}`, {
							state: { id: product.id },
						})
					}
					className="cursor-pointer relative w-[180px] h-[180px]   md:w-[197px] md:h-[197px] rounded-[4px] overflow-hidden">
					<img
						src={product.images || item}
						alt="item"
						className="w-full h-full object-cover rounded-sm items-stretch"
					/>
					<div className="absolute top-0 w-full left-0 z-20 py-2 px-1 rounded-br-[100px] flex items-center sm:justify-center bg-white gap-[10px] ">
						<>
							<div className="hidden sm:flex whitespace-nowrap text-wrap overflow-hidden text-center text-green-700 text-xs font-[700]">
								{(() => {
									const companyName =
										product?.companyName || "";
									return companyName.length > 17
										? companyName.slice(0, 17) + "..."
										: companyName;
								})()}
							</div>
							<div className="flex sm:hidden whitespace-nowrap text-wrap overflow-hidden  text-green-700 text-xs font-[700]">
								{(() => {
									const companyName =
										product?.companyName || "";
									return companyName.length > 12
										? companyName.slice(0, 12) + "..."
										: companyName;
								})()}
							</div>
						</>
					</div>
					<div className=" hidden sm:flex w-[50px] h-[50px] border-[3px]  z-10 border-[#24FF00] absolute bottom-1 right-1 rounded-full bg-green-100 lg:bg-green-100   flex-col items-center justify-center gap-1">
						<img
							src={Shield}
							alt="Shield Icon"
							className="w-3 h-3"
						/>
						<div className="text-[6.5px] text-wrap text-center font-medium text-[#047857]">

							CIPL <br /> Verified
						</div>
					</div>
					{product.status ? (
						<div className="flex sm:hidden items-center absolute bottom-1 right-2">
							<img
								src={Verification}
								className="h-7 w-7 z-20"
								alt=""
							/>{" "}
							<div className=" ml-[-3px] px-1 py-[2px] text-xs rounded-r-lg border-green-300 bg-green-100 text-green-700">
								{" "}
								Active
							</div>
						</div>
					) : (
						<div className="flex sm:hidden  items-center absolute bottom-1 right-2">
							<img
								src={Unchecked}
								className="h-7 w-7 z-20"
								alt=""
							/>{" "}
							<div className=" ml-[-3px] px-1 py-[2px] text-xs rounded-r-lg border-[#931515] bg-red-100 text-red-700">
								{" "}
								InActive
							</div>
						</div>
					)}
				</div>

				<div className="flex w-full justify-between sm:py-3 relative">
					<div className="flex flex-col gap-[8px]">
						<div
							className="font-[500] text-[12px] sm:text-[18px] leading-[21px] cursor-pointer 2xl:max-w-[500px] text-wrap"
							onClick={() =>
								navigate(`/singleproduct/${product.id}`, {
									state: { id: product.id },
								})
							}>
							{product.productName}
						</div>

						<div className="flex flex-col gap-[8px]">
							<div className="font-[500] text-[14px] leading-[17px] flex items-center gap-[9px]">
								{product.finalPrice
									? `₹${product.finalPrice.toFixed(2)}/${product.unit
									}`
									: "Price not available"}
							</div>

							<div className="font-400 text-[10px] sm:text-[14px] leading-[22px] text-blue-400 flex items-center gap-2">
								<span>(For Best Rate, Please Contact Us)</span>
								<img
									src={whatsapp}
									className="w-[22px] h-[22px] cursor-pointer"
									onClick={handleWhatsappClick}
									alt="whatsapp"
								/>
							</div>
						</div>

						{product.rewards ? (
							<div className="flex  flex-col items-start sm:flex-row sm:items-center">
								<span className="text-[15px] pb-1 sm:pb-0">
									You will earn{" "}
								</span>
								<div className=" flex items-center">
									<img
										className="mr-2 sm:mx-2"
										src={icon}
										alt="icon"
									/>{" "}
									<span className="text-[15px] sm:text-[20px] mr-2 text-nowrap">
										{product.rewards} super coins
									</span>
								</div>
							</div>
						) : null}

					</div>

					{product.status ? (
						<div className="hidden sm:flex items-center absolute right-1 top-0">
							<img
								src={Verification}
								className="h-7 w-7 z-20"
								alt=""
							/>{" "}
							<div className=" ml-[-3px] px-1 py-[2px] text-xs rounded-r-lg border-green-300 bg-green-100 text-green-700">
								Active
							</div>
						</div>
					) : (
						<div className="hidden sm:flex items-center absolute right-1 top-0">
							<img
								src={Unchecked}
								className="h-7 w-7 z-20"
								alt=""
							/>{" "}
							<div className=" ml-[-3px] px-1 py-[2px] text-xs rounded-r-lg border-[#931515] bg-red-100 text-red-700">
								InActive
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default AssignedCategoryItemCard;
