import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAllCategoriesQuery, useNavbarDataQuery } from "../../Redux/Api/Category";
import { motion } from "framer-motion";
import { Bars } from "react-loader-spinner";
import { ScaleLoader } from "react-spinners";

import { useGetCompanyProductMutation } from "../../Redux/Api/Admin/Company";
import { useDispatch, useSelector } from "react-redux";
import {
  addCompany,
  clearResults,
  resetCompany,
} from "../../Redux/reducer/Filter";
import { setAllCategories, setAllCompanies } from "../../Redux/reducer/toStoreValues";
import { useTranslation } from "react-i18next";
import defaultImage from "../../assets/item.png"
interface CompanyName {
  basicRate: number;
  description: string;
  id: string;
  image: string;
  name: string;
  productCount: number;
  publish: boolean;
  srNo: number;
}

const Navbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { data: categoriesData, isLoading: categoriesLoading } =  useAllCategoriesQuery();
  const [mutation, { data: companyName }] = useGetCompanyProductMutation();


  const { data: navCountData } = useNavbarDataQuery()

  useEffect(() => {
    mutation({
      category: [],
      search:""
    });
  }, [mutation]);

  const [activeItem, setActiveItem] = useState(location.pathname);
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [showCompanyDropdown, setShowCompanyDropdown] = useState(false);

  useEffect(() => {
    if (categoriesData) {
      dispatch(setAllCategories(categoriesData?.data))
    }
  }, [categoriesData])

  useEffect(() => {
    if (companyName) {
      dispatch(setAllCompanies(companyName?.data))
    }
  }, [companyName])

  useEffect(() => {
    setActiveItem(location.pathname);
  }, [location]);

  const resetActiveItem = () => {
    setActiveItem(location.pathname);
  };
  const handleItemPage = () => {
    dispatch(clearResults());
  };

  const handleItemClick = (itemName: any) => {
    setActiveItem(`/items`);
    resetActiveItem();
    navigate(`/items`, { state: { id: itemName } });
    setShowCategoryDropdown(false);
    setShowCompanyDropdown(false);
  };

  const handleClick = (itemName: any) => {
    setActiveItem(`/items`);
    resetActiveItem();
    dispatch(resetCompany());
    dispatch(clearResults());
    dispatch(addCompany(itemName));
    navigate(`/items`, { state: { company: itemName } });
    setShowCategoryDropdown(false);
    setShowCompanyDropdown(false);
  };

  const handleCategoryHover = (value: boolean) => {
    setShowCategoryDropdown(value);
  };

  const handleCompanyyHover = (value: boolean) => {
    setShowCompanyDropdown(value);
  };

  const handleClose = () => {
    setShowCategoryDropdown(false);
    setShowCompanyDropdown(false);
  }
  // Faster motion variants for dropdown and items
  const dropdownVariants = {
    hidden: { opacity: 0, height: 0 },
    visible: {
      opacity: 1,
      height: "auto",
      transition: {
        duration: 0.3,  
        when: "beforeChildren",
        staggerChildren: 0.01, 
      },
    },
    exit: {
      opacity: 0,
      height: 0,
      transition: { duration: 0.2 },  
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: -5 },  
    visible: { opacity: 1, y: 0, transition: { duration: 0.15 } },  
  };
  return (

    <nav
      className="bg-white w-full  p-1 items-center gap-6 font-inter text-[#252F3D] font-semibold text-sm  hidden sm:block sticky   z-40 border shadow-sm"
    >
      <div className="relative flex items-center justify-evenly w-full  font-inter text-[#252F3D] font-semibold text-sm cursor-pointer  group">
        <Link
          to="/"
          onClick={resetActiveItem}
          className={`${activeItem === "/" ? "text-red-500" : "hover:text-blue-400"
            } hover:underline`}
        >
          Home
        </Link>
        <div
          className="group"
          onMouseEnter={() => handleCategoryHover(true)}
          onMouseLeave={() => handleCategoryHover(false)}
        >
          <div className="text-gray-800 hover:text-gray-900 px-4 py-2 cursor-pointer">
            <Link
              to={"/company"}
              className={`${activeItem === "/company"
                ? "text-red-500"
                : "hover:text-blue-400"
                } hover:underline`}
            >
              <div className="flex gap-1">
                <span>Company</span>
                <span> ({navCountData?.data?.companyCount})</span>
              </div>
            </Link>
          </div>
          {showCategoryDropdown && (
            <div
              className={`${showCategoryDropdown
                ? "max-h-[100px] opacity-100"
                : "max-h-[350px] "
                } `}
            >
              {showCategoryDropdown && (
                <motion.div
                  className="absolute bg-white top-8 w-[90%] left-20 z-50 p-2 shadow-lg"
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  variants={dropdownVariants}
                >
                  <div className="grid grid-cols-4 lg:grid-cols-5 gap-2">
                    {companyName?.data?.slice(0, 60).map((company: any, index: number) => (
                      <motion.div
                        key={index}
                        className="flex gap-x-1 px-2 text-[15px] py-1 text-gray-500 hover:text-black font-[400]"
                        variants={itemVariants}
                      >
                        <img
                          src={company.image || defaultImage}
                          alt={company.name}
                          className="h-[15px] w-[15px] rounded-full border-[1px] border-gray-400 mt-1 object-cover"
                        />
                        <a href="#" onClick={() => handleClick(company.id)}>
                          {company.name}
                        </a>
                      </motion.div>
                    ))}

                    <Link to={"/company"} onClick={() => handleCategoryHover(false)}>
                      <span className="text-blue-600 text-[14px]">See more</span>
                    </Link>
                  </div>
                </motion.div>
              )}
              {/* <div className="absolute  bg-white top-8 w-[90%] left-20 z-50  p-2 shadow-lg">
                <div className="grid grid-cols-4 lg:grid-cols-5 gap-2 ">
                  {companyName &&
                    companyName?.data
                      ?.slice(0, 70)
                      .map((company: CompanyName, index: number) => (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <div className="">
                      
                          <a
                            key={index}
                            href="#"
                            className=" px-2 text-[15px] py-1 text-gray-500 hover:text-black flex gap-x-1 font-[400] "
                            onClick={() => handleClick(company.id)}
                          >
                               <img src={company.image || defaultImage} alt="" className="h-[15px] w-[15px] rounded-full border-[1px] border-gray-400 mt-1 object-cover" />  {company.name}
                          </a>
                        </div>
                      ))}

                  <Link to={"/company"} onClick={() => handleCategoryHover(false)}>
                    <span className="text-blue-600 text-[12px]">See more</span>
                  </Link>
                </div>
              </div> */}
            </div>
          )}


        </div>

        <div
          className="group"
          onMouseEnter={() => handleCompanyyHover(true)}
          onMouseLeave={() => handleCompanyyHover(false)}
        >
          <div className="text-gray-800 hover:text-gray-900 px-4 py-2 cursor-pointer">
            <Link
              to={"/categories"}
              className={`${activeItem === "/categories"
                ? "text-red-500"
                : "hover:text-blue-400"
                } hover:underline`}
            >
              <div className="flex gap-1">
                <span>Category</span>
                <span> ({navCountData?.data?.categoryCount})</span>
              </div>
            </Link>
          </div>
          {showCompanyDropdown && (
            <motion.div className="absolute  bg-white top-8 w-[90%] left-20 z-50  p-2 shadow-lg" initial="hidden"
              animate="visible"
              exit="exit"
              variants={dropdownVariants}>
              {categoriesLoading ? (
                <div className="items-center  m-auto w-[70%]">
                  <ScaleLoader
                    color="#565958"
                    margin={3}
                    width={4}
                    height={23}
                  />
                </div>
              ) : (
                <div className="grid grid-cols-4 lg:grid-cols-5 gap-2  ">
                  {categoriesData &&
                    categoriesData?.data?.map((category: any, index: any) => (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <motion.a
                        key={index}
                        href="#"
                        className=" px-2 text-[15px] py-1 text-gray-500 hover:text-black font-[400] "
                        onClick={() => handleItemClick(category.id)}
                        variants={itemVariants}
                      >
                        {category.name}
                      </motion.a>
                    ))}

                  <Link to={"/categories"} onClick={() => handleCompanyyHover(false)}>
                    <span className="text-blue-600 text-[14px]">See more</span>
                  </Link>
                </div>
              )}
            </motion.div>
          )}
        </div>

        <div onClick={handleItemPage}>
          <Link
            to="/items"
            className={`${activeItem === "/items" ? "text-red-500" : "hover:text-blue-400"
              } hover:underline`}
          >
            
            <div className="flex gap-1">
                <span>Product</span>
                <span> ({navCountData?.data?.productCount})</span>
              </div>
          </Link>
        </div>
        <div onClick={handleItemPage}>
          <Link
            to="/assignrewards"
            className={`${activeItem === "/assignrewards" ? "text-red-500" : "hover:text-blue-400"
              } hover:underline`}
          >

            <div className="flex gap-1">
              <span>Assigned Rewards</span>
              <span> ({navCountData?.data?.contractorData})</span>
            </div>

          </Link>
        </div>
        <div>
          <Link
            to="/aboutus"
            className={`${activeItem === "/aboutus" ? "text-red-500" : "hover:text-blue-400"
              } hover:underline`}
          >
            About Us
          </Link>

        </div>
        <div>
          <Link
            to="/order "
            className={`${activeItem === "/order" ? "text-red-500" : "hover:text-blue-400"
              } hover:underline`}
          >
            OrderTracking
          </Link>
        </div>
        <div>
          <Link
            to="/contactus"
            className={`${activeItem === "/contactus"
              ? "text-red-500"
              : "hover:text-blue-400"
              } hover:underline`}
          >
            Contact Us
          </Link>
        </div>
      </div>

    </nav>
  );
};

export default Navbar;
