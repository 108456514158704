import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { useNavigate } from "react-router-dom";
import { BiSolidCoinStack } from "react-icons/bi";
import { PiUserSwitch } from "react-icons/pi";
import { ConfirmModal, ZoomInImage } from "../../../utils/CustomModal";
import shape from "../../../assets/profile/ProfileShape.png";
import DefaultImage from "../../../assets/profile/DefualtProfile.png";
import coverImage from "../../../assets/profile/coverImage.png";
import smCoverImage from "../../../assets/profile/smCoverImage.jpg";
import { toast } from "react-toastify";
import { useChangeBagroundImageMutation } from "../../../Redux/Api/Contractor";
import { useCreateImageURLMutation } from "../../../Redux/Api/Admin/Image";
import MyProfileInfo from "./MyProfileInfo";
import { TbPhotoEdit } from "react-icons/tb";
export const MyProfile = () => {
  const userDetails = useSelector((state: RootState) => state.Login.ContractorData);
  const navigate = useNavigate();
  const [isZoomInProfile, setIsZoomInProfile] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const [updateBackgroundImage, { isLoading, isSuccess, isError }] = useChangeBagroundImageMutation();
  const [getImageURL] = useCreateImageURLMutation();

  const role = userDetails?.role;

  const styles = {
    boxShadowStyle: {
      boxShadow: "0px 0.5px 7px 0px #00000040",
    },
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Background image updated successfully!", {
        position: "top-center",
      });
      setIsConfirmModalOpen(false);
      setImagePreview(null);
      setUploadedFile(null);
    } else if (isError) {
      toast.error(
        "Failed to update background image. Please try again later."
      );
      setIsConfirmModalOpen(false);
    }
  }, [isSuccess, isError]);

  const handleZoomProfile = () => {
    if (userDetails?.avatar) {
      setIsZoomInProfile(true);
    } else {
      toast.error("Please upload your profile picture to zoom in.");
    }
  };

  const handleBackgroundChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setUploadedFile(file);
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64Image = reader.result as string;
        setImagePreview(base64Image);
        setIsConfirmModalOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    if (uploadedFile) {
      const body = new FormData();
      body.append("image", uploadedFile);
      try {
        const response = await getImageURL(body).unwrap();
        updateBackgroundImage({ image: response[0] });
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const onClose = () => {
    setIsConfirmModalOpen(false);
    setUploadedFile(null);
    setImagePreview(null);
  };

  return (
    <div className="w-full min-h-screen flex flex-col relative">
      <div className="flex-grow overflow-hidden w-full mx-auto h-auto">
        <div
          className="w-full mx-auto border-white border-b-[4px] h-auto relative"
          style={styles.boxShadowStyle}>
          <img
            src={
              imagePreview
                ? imagePreview
                : userDetails?.backgroundImage || coverImage
            }
            alt="User Cover"
            className="w-full h-[156px] 2xl:h-[170px] object-cover object-center hidden sm:block"
          />
          <img
            src={
              imagePreview
                ? imagePreview
                : userDetails?.backgroundImage || smCoverImage
            }
            alt="User Cover"
            className="w-full h-[140px] object-cover object-center block sm:hidden"
          />
          <div className="absolute right-4 bottom-4">
            <button
              onClick={() =>
                document
                  .getElementById("backgroundInput")
                  ?.click()
              }
              className="w-11 h-11 flex justify-center items-center bg-white rounded-full ">
              <TbPhotoEdit fontSize={"1.60rem"} />
            </button>
            <input
              type="file"
              id="backgroundInput"
              className="hidden"
              onChange={handleBackgroundChange}
              accept="image/*"
            />
          </div>
        </div>
        <div className="flex flex-col  justify-center sm:flex-row w-full max-w-[1536px] items-start sm:justify-start font-inter ">
          <div className="w-full  flex flex-col items-center sm:items-start sm:w-44 mr-2 ">
            <div
              className=" flex justify-center  sm:ml-10 w-32 h-32 relative -mt-16 border-white bg-white rounded-full overflow-hidden "
              style={styles.boxShadowStyle}>
              <img
                onClick={handleZoomProfile}
                className="object-cover object-center h-32 w-32 border-4 rounded-full border-white hover:scale-90 duration-300 cursor-pointer"
                src={userDetails?.avatar || DefaultImage}
                alt="Profile"
              />
            </div>
            {role === "admin" && (
              <button
                onClick={() => {
                  window.location.reload();
                  navigate("/admin");
                }}
                className="py-[6px] px-1  rounded-xl bg-[#5097A4] flex justify-center gap-1 text-white font-medium sm:ml-10 w-32 text-xs items-center mt-2 hover:scale-125 duration-300 border-2 border-white"
                style={styles.boxShadowStyle}>
                {" "}
                <PiUserSwitch />
                Go to Admin
              </button>
            )}
          </div>

          <div className="flex flex-col items-center  sm:flex-row sm:justify-around md:justify-between w-full sm:w-[calc(100%-176px)] relative sm:items-start gap-2 mt-4 ">
            <div className="text-xl md:text-[25px] ml-1 font-bold">
              {userDetails?.name}
            </div>
            <div
              className="flex items-center sm:mr-10 py-1 px-3 text-[17px] md:text-[20px] font-bold rounded-2xl tracking-wider"
              style={styles.boxShadowStyle}>
              <BiSolidCoinStack className="text-[#FF9900] mr-[1px] " />
              {userDetails?.totalReward || 0}
            </div>
          </div>
        </div>

        <MyProfileInfo />

        <ZoomInImage
          isOpen={isZoomInProfile}
          setIsOpen={setIsZoomInProfile}
          imageUrl={userDetails?.avatar}
          altText="abc"
        />
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          handleConfirm={handleSubmit}
          handleClose={onClose}
          text="Are you sure you want to change your background image?"
          heading="Confirm Background Change"
          isLoading={isLoading}
        />
      </div>
      <img
        src={shape}
        alt=""
        className="w-full px-10 object-contain mt-auto rounded-xl"
      />
    </div>
  );
};
