import { RxCross2 } from "react-icons/rx";
import { RiImageAddLine } from "react-icons/ri";
import { useEffect, useState } from "react";
import { useChangeImageMutation } from "../../../../Redux/Api/Admin/Reward";
import { toast } from "react-toastify";
import { AnimatePresence, motion } from "framer-motion";

type FormValues = {
  image: File | null;
};

export const UploadImage = ({ onClose, id }: any) => {
  const [couponData, setCouponData] = useState<FormValues>({ image: null });
  const [uploadImage, { isSuccess, isError,isLoading }] = useChangeImageMutation();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setCouponData((prevState) => ({ ...prevState, image: file }));
  };

  const triggerFileInput = () => {
    const fileInput = document.getElementById("image") as HTMLInputElement;
    fileInput?.click();
  };



  useEffect(() => {
    if (isSuccess) {
      toast.success("Image uploaded successfully");
      onClose();
    }
    if (isError) {
      toast.error("Error uploading image");
    }
  }, [isSuccess, isError, onClose]);

  const handleSubmit = async () => {
    try {
      if (!couponData.image) {
        toast.warn("Please select an image before submitting.");
        return;
      }

      const formData = new FormData();
      formData.append("image", couponData.image);

      await uploadImage({ couponData: formData, id });
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Unexpected error occurred. Please try again.");
    }
  };


  return (
    <AnimatePresence
    >
      <motion.div
        id="progress-modal"
        tabIndex={-1}
        className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3, ease: "easeOut" }}
      >
        <motion.div
          className="w-[547px] flex flex-col gap-[15px] px-[16px] rounded-[8px] bg-white shadow-sm"
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          transition={{ duration: 0.3, ease: "easeOut" }}
        >
          <div className="flex justify-between px-[10px] py-[16px] text-[#334155] text-2xl">
            <span className="text-2xl font-bold">Upload Photo</span>
            <RxCross2
              className="w-[30px] h-[30px] cursor-pointer"
              onClick={onClose}
            />
          </div>
          <div className="gap-[12px] px-[10px] flex flex-col">
            <div className="flex flex-col gap-[26px]">
              <div className="w-full bg-white py-[6px] border-[3px] border-[#334155] border-dashed rounded-md flex flex-col justify-center items-center px-3">
                <div className="flex flex-col gap-[10px] items-center justify-center text-center my-2">
                  <RiImageAddLine fontSize={"3rem"} />
                  <div className="font-medium text-[16px] leading-[7px] text-black">
                    Upload Your Files
                  </div>
                  <div
                    className="text-[#334155] font-[400] text-[16px] leading-[24px] underline cursor-pointer"
                    onClick={triggerFileInput}
                  >
                    Browse
                  </div>
                  <input
                    type="file"
                    id="image"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="hidden"
                  />
                </div>
                {couponData.image && (
                  <div className="mt-2 mb-2 text-center">
                    <p className="text-gray-600 font-[500] text-[14px] leading-[24px]">
                      Selected File: {couponData.image.name}
                    </p>
                    <p className="text-gray-500 text-[12px]">
                      Size: {(couponData.image.size / 1024).toFixed(2)} KB
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-center pt-2 pb-5 px-4">
            <button
              className={`bg-[#334155] hover:bg-[#283443] text-white py-2 px-5 rounded-lg flex items-center justify-center shadow-md transition duration-200 focus:outline-none focus:ring-2  ${isLoading ? "cursor-not-allowed opacity-75" : ""
                }`}
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? "Submitting..." : "Submit"}
            </button>

          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};



// import { RxCross2 } from "react-icons/rx";
// import { RiImageAddLine, RiUploadCloud2Line } from "react-icons/ri";
// import { useState } from "react";
// import { useChangeImageMutation } from "../../../../Redux/Api/Admin/Reward";
// import { toast } from "react-toastify";
// import { AnimatePresence, motion } from "framer-motion";

// type FormValues = {
//   image: File | null;
// };

// export const UploadImage = ({ onClose, id }: any) => {
//   const [couponData, setCouponData] = useState<FormValues>({
//     image: null,
//   });
//   const [uploadImage] = useChangeImageMutation()


//   const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const file = e.target.files?.[0] || null;
//     setCouponData((prevState) => ({
//       ...prevState,
//       image: file,
//     }));
//   };

//   const triggerFileInput = () => {
//     const fileInput = document.getElementById("image") as HTMLInputElement;
//     fileInput?.click();
//   };

//   const handleSubmit = async () => {
//     try {
//       const formData = new FormData();
//       if (couponData.image) {
//         formData.append("image", couponData.image);
//       }

//       uploadImage({ couponData: formData, id })
//       toast.success(
//         "Image uploaded successfully", {
//         position: "top-center"
//       }
//       )
//       onClose();
//     } catch (error) {
//       console.error("Error creating reward:", error);
//     }
//   };

//   return (

//     <AnimatePresence>

//       <motion.div
//         id="progress-modal"
//         tabIndex={-1}
//         className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
//         initial={{ opacity: 0 }}
//         animate={{ opacity: 1 }}
//         exit={{ opacity: 0 }}
//         transition={{ duration: 0.2, ease: 'easeOut' }}
//       >
//         <motion.div className="w-[547px] flex flex-col gap-[15px] px-[16px] rounded-[8px] bg-white shadow-sm"
//           initial={{ scale: 0.95 }}
//           animate={{ scale: 1 }}
//           exit={{ scale: 0.95 }}
//           transition={{ duration: 0.2, ease: 'easeOut' }}
//         >
//           <div className="flex justify-between px-[10px]  py-[16px]  text-[#334155] text-2xl">
//             <span className="text-2xl font-bold">Upload Photo</span>
//             <RxCross2
//               className="w-[30px] h-[30px] cursor-pointer"
//               onClick={onClose}
//             />
//           </div>
//           <div className="gap-[12px] px-[10px] flex flex-col">


//             <div className="flex flex-col gap-[26px] ">
//               <div className="w-full bg-white py-[6px]  border-[3px] border-[#334155] border-dashed rounded-[12px] flex flex-col justify-center items-center px-3">
//                 <div className="flex flex-col gap-[10px] items-center justify-center text-center my-2 ">

//                   <RiImageAddLine fontSize={"3rem"} />


//                   <div className="font-medium text-[16px] leading-[7px] text-black ">
//                     Upload Your Files
//                   </div>
//                   <div
//                     className="text-[#334155] font-[400] text-[16px] leading-[24px] underline cursor-pointer"
//                     onClick={triggerFileInput}
//                   >
//                     Browse
//                   </div>
//                   <input
//                     type="file"
//                     id="image"
//                     accept="image/*"
//                     onChange={handleFileChange}
//                     className="hidden"
//                   />
//                 </div>
//                 {couponData.image && (
//                   <div className="mt-4 text-center">
//                     <p className="text-gray-600 font-[500] text-[14px] leading-[24px]">
//                       Selected File: {couponData.image.name}
//                     </p>
//                     <p className="text-gray-500 text-[12px]">
//                       Size: {(couponData.image.size / 1024).toFixed(2)} KB
//                     </p>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//           <div className="flex justify-center pt-2 pb-5 px-4">
//             <button
//               className="bg-[#334155] hover:bg-[#283443] text-white py-2 px-5 rounded-lg flex items-center justify-center shadow-md transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-300"
//               onClick={handleSubmit}
//             >
//               Submit
//             </button>
//           </div>

//         </motion.div>
//       </motion.div>


//     </AnimatePresence>
//   );
// };
