import { createSlice } from "@reduxjs/toolkit";

const RequestSlice = createSlice({
	name: "request",
	initialState: {
		isFilterVisible: false,
		isCategoryFilterVisible: false,
		isProfileVisible: false,
		isCouponVisible: false,

		//11/06/2024
		isCategoryPriceWiseFilterVisible: false,
	},
	reducers: {
		showFilter: (state) => {
			state.isFilterVisible = true;
		},
		closeFilter: (state) => {
			state.isFilterVisible = false;
		},
		toggleCategoryFilter: (state) => {
			state.isCategoryFilterVisible = !state.isCategoryFilterVisible;
		},
		closeCategoryFilter: (state) => {
			state.isCategoryFilterVisible = false;
		},
		showProfile: (state) => {
			state.isProfileVisible = true;
		},
		closeProfile: (state) => {
			state.isProfileVisible = false;
		},
		showCoupon: (state) => {
			state.isCouponVisible = true;
		},
		closeCoupon: (state) => {
			state.isCouponVisible = false;
		},

      //11/06/2024
		toggleCategoryPriceWiseFilter: (state) => {
			state.isCategoryPriceWiseFilterVisible = !state.isCategoryPriceWiseFilterVisible;
		},
		closeCategoryPriceWiseFilter: (state) => {
			state.isCategoryPriceWiseFilterVisible = false;
		},
	},
});

export const {
	showFilter,
	closeFilter,
	toggleCategoryFilter,
	closeCategoryFilter,
	showProfile,
	closeProfile,
	showCoupon,
	closeCoupon,
    //11/06/2024
  toggleCategoryPriceWiseFilter,
  closeCategoryPriceWiseFilter
} = RequestSlice.actions;
export default RequestSlice.reducer;
