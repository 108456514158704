    // src/Redux/Slices/categorySlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ICategory {
    id: string;
    image?: string;
    name: string;
    itemCount: number;
}

interface CategoryState {
    category: ICategory | null;
}

const initialState: CategoryState = {
    category: null,
};

const categorySlice = createSlice({
    name: "assignedRewards",
    initialState,
    reducers: {
        setCategory: (state, action: PayloadAction<ICategory>) => {
            state.category = action.payload;
        },
        clearCategory: (state) => {
            state.category = null;
        },
    },
});

export const { setCategory, clearCategory } = categorySlice.actions;
export default categorySlice.reducer;
