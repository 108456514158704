import Sliders from "./Slidder";
import { Certificates } from "./Certificates";
import { chawlaispat } from "../../Redux/apiRoutes";
import usePageMeta from "../../common/hooks/usePageMeta";


import OurClients from "./components/OurClients";
import AboutSection from "./components/AboutSection";

const AboutUs = () => {
  const title =
    "About Us | Building Material Supplier | Chawla Ispat";
  const description =
    "Learn more about Chawla Ispat and our mission, values, and services.";
  const baseUrl = chawlaispat;

  usePageMeta(title, description, baseUrl);

  return (
    <div className="font-inter bg-white mb-4">
      <div className="flex flex-col gap-[36px] w-[90%] mx-auto  ">
        <div className="px-auto  py-[40px] flex flex-col items-center gap-[20px] ">
          <div className="flex flex-col gap-[24px] items-center my-2 sm:my-4">
            <h1
              className="font-[700]  text-[40px] leading-[38px] text-[#5097A4]"
              style={{
                textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}>
              About Us
            </h1>
            <h2 className="font-[500] text-[16px]  lg:text-[22px]  text-slate-600 w-full lg:w-[80%] mx-auto text-center ">
              We offer instant online quotes, payment and ordering
              for high-quality, high-, custom metal parts –
              straight from our factory.
            </h2>
          </div>
          <AboutSection />
          <OurClients />
        </div>
      </div>
      <div className="my-[30px] md:my-[50px] mx-auto px-[30px] w-full md:w-full flex justify-center items-center ">
        <Sliders />
      </div>
      <div className="mx-auto px-[10px] md:w-full flex justify-center ">
        <Certificates />
      </div>
    </div>
  );
};

export default AboutUs;
