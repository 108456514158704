import { chawlaispat } from '../../../Redux/apiRoutes';
import usePageMeta from '../../../common/hooks/usePageMeta';

const AssigendHeader = ({ handleNavigate }: any) => {
    const title =
        "Assigned Rewards |  Building Material Supplier | Chawla Ispat ";
    const description = "description";
    const baseUrl = chawlaispat;

    usePageMeta(title, description, baseUrl);
    return (
        <div className="flex justify-between mb-4">
            <div className="font-[700]  text-[25px] sm:text-[32px] leading-[38px]" >
                Assigned Rewards
            </div>
    
        </div>
    )
}

export default AssigendHeader