import ComapnyCard from "./CompanyCard";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Company } from "../Components/types";
import { chawlaispat } from "../../Redux/apiRoutes";
import usePageMeta from "../../common/hooks/usePageMeta";
import { IoIosSearch } from "react-icons/io";
import { RingLoader } from "react-spinners";
import NoData from "../../common/NoData";
import { useGetCompanyProductMutation } from "../../Redux/Api/Admin/Company";
import useDebounce from "../../common/hooks/useDebounce";

const CompanyPage = () => {
	const [search, setSearch] = useState<string>("")
	const [isFocused, setIsFocused] = useState<boolean>(false);
	const inputRef = useRef<HTMLInputElement>(null);

	const handleSearchClick = () => {
		if (inputRef.current) {
			inputRef.current.blur();
		}
	};
	const [mutation, { data: allCompanies, isLoading }] = useGetCompanyProductMutation();
	
	const onChnageHandler = (e: ChangeEvent<HTMLInputElement>) => { setSearch(e.target.value) };

	const debounceCompanySearch = useDebounce(search, 500)

	useEffect(() => {
		mutation({
			category: [],
			search: debounceCompanySearch
		});
	}, [mutation, debounceCompanySearch]);


	const title = "Company | Building Material Supplier | Chawla Ispat";
	const description = "Learn more about our company and what we offer at Chawla Ispat.";
	const canonicalUrl = chawlaispat;

	usePageMeta(title, description, canonicalUrl);

	return (
		<div className="font-inter bg-white">
			<div className="py-12 w-11/12 m-auto ">
				<div className="flex justify-between flex-col sm:flex-row">
					<div className="font-[700] text-[25px] sm:text-[32px] leading-[38px]">
						All Company
					</div>
					<div className="relative sm:mr-6 my-2 border rounded-md   ">
						<input
							type="text"
							ref={inputRef}
							className="input-style"
							placeholder="Search"
							onFocus={() => setIsFocused(true)}
							onBlur={() => setIsFocused(false)}
							onChange={onChnageHandler}
							value={search}
						/>
						{!isFocused && (
							<span
								onClick={handleSearchClick}
								className={`absolute right-3 top-1/2 transform -translate-y-1/2 text-2xl text-gray-600 cursor-pointer transition-all duration-300 ${isFocused
									? "translate-x-5 opacity-0 pointer-events-none"
									: "translate-x-0 opacity-100"
									}`}>
								<IoIosSearch />
							</span>
						)}
					</div>
				</div>

				{isLoading ? (
					<div className="flex justify-center items-center h-[50vh]">
						<RingLoader color="#5097A4" size={150} />
					</div>
				) : allCompanies?.data?.length > 0 ? (
					<div className="flex flex-wrap justify-center gap-4 sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-6 py-8">
						{allCompanies?.data?.map((company: Company, i: number) => (
								<div
									className="flex justify-center w-full"
									key={i}>
									<ComapnyCard
										key={company.id}
										company={company}
									/>
								</div>
							))}
					</div>
				) : (
					<NoData messageText="No companies found. Please check your filters." />
				)}

				
			</div>
		</div>
	);
};

export default CompanyPage;



// import ComapnyCard from "./CompanyCard";
// import { useDispatch, useSelector } from "react-redux";
// import { RootState } from "../../Redux/store";
// import { ChangeEvent, useEffect, useRef, useState } from "react";
// import { Company } from "../Components/types";
// import { chawlaispat } from "../../Redux/apiRoutes";
// import usePageMeta from "../../common/hooks/usePageMeta";
// import { IoIosSearch } from "react-icons/io";
// import { setSearchCompanies } from "../../Redux/reducer/toStoreValues";
// import { RingLoader } from "react-spinners";
// import NoData from "../../common/NoData";
// import { useLocation } from "react-router-dom";

// const CompanyPage = () => {
// 	const dispatch = useDispatch();
// 	const location = useLocation();
// 	const [visibleCount, setVisibleCount] = useState<number>(12);
// 	const [isDesktop, setIsDesktop] = useState<boolean>(false);
// 	const [isFocused, setIsFocused] = useState<boolean>(false);
// 	const inputRef = useRef<HTMLInputElement>(null);

// 	const handleSearchClick = () => {
// 		if (inputRef.current) {
// 			inputRef.current.blur();
// 		}
// 	};
// 	const allCompanies = useSelector(
// 		(state: RootState) => state.values.allCompanies
// 	);
// 	const { searchCompanies, companyLoading } = useSelector(
// 		(state: RootState) => state.values
// 	);

// 	// const filterCompanies = search ? allCompanies.filter((company: Company) => company.name.toLowerCase().includes(search.toLowerCase())) : allCompanies;

// 	const onChnageHandler = (e: ChangeEvent<HTMLInputElement>) => {
// 		dispatch(setSearchCompanies(e.target.value));
// 	};

// 	useEffect(() => {
// 		dispatch(setSearchCompanies(""));
// 	}, [location, dispatch]);

// 	useEffect(() => {
// 		const handleResize = () => {
// 			if (window.innerWidth >= 640) {
// 				setIsDesktop(true);
// 				setVisibleCount(allCompanies.length);
// 			} else {
// 				setIsDesktop(false);
// 				setVisibleCount(12);
// 			}
// 		};
// 		handleResize();
// 		window.addEventListener("resize", handleResize);

// 		return () => {
// 			window.removeEventListener("resize", handleResize);
// 		};
// 	}, [allCompanies.length]);

// 	const seeMore = () => {
// 		setVisibleCount((prev) => prev + 12);
// 	};

// 	const title = "Company | Building Material Supplier | Chawla Ispat";
// 	const description =
// 		"Learn more about our company and what we offer at Chawla Ispat.";
// 	const canonicalUrl = chawlaispat;

// 	usePageMeta(title, description, canonicalUrl);

// 	return (
// 		<div className="font-inter bg-white">
// 			<div className="py-12 w-11/12 m-auto ">
// 				<div className="flex justify-between flex-col sm:flex-row">
// 					<div className="font-[700] text-[25px] sm:text-[32px] leading-[38px]">
// 						All Company
// 					</div>
// 					<div className="relative sm:mr-6 my-2 border rounded-md   ">
// 						<input
// 							type="text"
// 							ref={inputRef}
// 							className="bg-purple-white shadow rounded-md border-0 p-3 w-full focus:outline-none focus:ring-2 focus:ring-[#5097A4] focus:border-[#5097A4] transition duration-200"
// 							placeholder="Search"
// 							onFocus={() => setIsFocused(true)}
// 							onBlur={() => setIsFocused(false)}
// 							onChange={onChnageHandler}
// 							value={searchCompanies}
// 						/>
// 						{!isFocused && (
// 							<span
// 								onClick={handleSearchClick}
// 								className={`absolute right-3 top-1/2 transform -translate-y-1/2 text-2xl text-gray-600 cursor-pointer transition-all duration-300 ${
// 									isFocused
// 										? "translate-x-5 opacity-0 pointer-events-none"
// 										: "translate-x-0 opacity-100"
// 								}`}>
// 								<IoIosSearch />
// 							</span>
// 						)}
// 					</div>
// 				</div>

// 				{companyLoading ? (
// 					<div className="flex justify-center items-center h-[50vh]">
// 						<RingLoader color="#5097A4" size={150} />
// 					</div>
// 				) : allCompanies.length > 0 ? (
// 					<div className="flex flex-wrap justify-center gap-4 sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-6 py-8">
// 						{allCompanies
// 							?.slice(0, visibleCount)
// 							?.map((company: Company, i: number) => (
// 								<div
// 									className="flex justify-center w-full"
// 									key={i}>
// 									<ComapnyCard
// 										key={company.id}
// 										company={company}
// 									/>
// 								</div>
// 							))}
// 					</div>
// 				) : (
//           <NoData messageText="No companies found. Please check your filters." />
// 				)}

// 				{!companyLoading && !isDesktop && visibleCount < allCompanies.length && (
// 					<div className="flex justify-center mt-8">
// 						<div className="flex justify-end mt-6 p-4">
// 							<button
// 								onClick={seeMore}
// 								className="bg-[#5097A4] bg-opacity-95 text-white px-6 py-2 rounded-lg hover:bg-[#7ab4be] duration-300">
// 								See More...
// 							</button>
// 						</div>
// 					</div>
// 				)}
// 			</div>
// 		</div>
// 	);
// };

// export default CompanyPage;
