import { useCallback, useEffect, useMemo, useState } from "react";
import ItemCard from "./Components/ItemCard";
import { useLocation } from "react-router-dom";
import { Filters } from "./Components/Filters";
import Companies from "./Components/Companies";
import noProductFound from "../../assets/noProduct.png"
import { useSearchFiltersMutation } from "../../Redux/Api/ProductAPI";
import { CombinedState } from "../../types/api-types";
import Pagination from "./pagination";
import { MobileFilter } from "./Components/MobileFilter";
import { RingLoader, } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { addCompany, clearResults, resetPage, setCurrentPage, setSelectedCategories, } from "../../Redux/reducer/Filter";
import { Products } from "./Components/types";
import { DetailsBar } from "./Components/DetailsBar";
import RelatedSearch from "./Components/RelatedSearch";
import ErrorBoundary from "../../common/ErrorBoundaries";
import { chawlaispat } from "../../Redux/apiRoutes";
import usePageMeta from "../../common/hooks/usePageMeta";
import { debounce } from "lodash";


const Items = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  let cat = location.state ? location?.state?.id : null;
  let company = location.state ? location?.state?.company : null;
  const [sidebarCategory, setSidebarCategory] = useState<any>([]);
  const [sidebarCompany, setSidebarCompany] = useState<any>([]);
  const [searchFilters, { data: SearchProductsResponse, isLoading: isPriceLoading }] = useSearchFiltersMutation({},);
  const currentPage = useSelector((state: RootState) => state.selection.currentPage);
  const [isFetching, setIsFetching] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [combinedState, setCombinedState] = useState<CombinedState>({
    search: null,
    company: [],
    category: [],
    limit: 10,
    page: currentPage,
  });

  const [filters, setFilters] = useState<boolean>(false);
  const [smFilter, setSmFilter] = useState<any>();
  const [comFilter, setComFilter] = useState<any>();
  const [filterPosition, setFilterPosition] = useState(0);
  const [limit, setLimit] = useState<number>(6)

  const categoryBox = useSelector((state: RootState) => state.selection.selectedCategories);
  const companyBox = useSelector((state: RootState) => state.selection.selectedCompanies);
  const getMainSearch = useSelector((state: RootState) => state.selection.selectedSearch);

  useEffect(() => {
    if (location.pathname === '/items') {
      if (location.state?.fromSingleProduct) {
      } else {
        dispatch(resetPage());
      }
    }
  }, [location, dispatch]);


  const SmallFilter = (data: any) => {
    setSmFilter(data);
  };

  const SmallCompanyFilter = (data: any) => {
    setComFilter(data);
  };

  useEffect(() => {
    if (smFilter || comFilter) {
      setCombinedState((prevState) => ({
        ...prevState,
        search: null,
        category: smFilter || prevState.category,
        company: comFilter || prevState.company,
        page: 1,
      }));
      if (smFilter) setSidebarCategory(smFilter);
      if (comFilter) setSidebarCompany(comFilter)
    }
  }, [smFilter, comFilter]);



  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       await searchFilters({ combinedState });
  //     } catch (error) {
  //       console.log(error, "Error found");
  //     }
  //   };
  //   fetchData();
  // }, [combinedState, searchFilters]);


  const fetchFilteredData = useCallback(
    debounce(async (state) => {
      setIsFetching(true);
      try {
        await searchFilters({ combinedState: state });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsFetching(false);
      }
    }, 300),
    [searchFilters]
  );


  useEffect(() => {
    if (combinedState && Object.keys(combinedState).length > 0) {
      fetchFilteredData(combinedState);
    }
  }, [combinedState, fetchFilteredData]);


  useEffect(() => {
    setIsFetching(true);
    return () => {
      setIsFetching(false);
    };
  }, []);


  useEffect(() => {
    setCombinedState((prevState) => ({
      ...prevState,
      page: currentPage,
    }));
  }, [currentPage]);

  const onPageChange = (pageNumber: number) => {
    dispatch(setCurrentPage(pageNumber));
    setCombinedState((prevState) => ({
      ...prevState,
      page: pageNumber,
    }));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const close = (data: any) => {
    setSidebarCategory([]);
    setSidebarCompany([]);
    setFilters(data);
  };

  const handleFilterButtonClick = () => {
    setFilters(!filters);
    if (!filters) {
      setFilterPosition(0);
    }
  };


  // useEffect(() => {
  //   const handleResize = () => {
  //     const newLimit = window.innerWidth < 820 ? 15 : 6
  //     setLimit(newLimit)
  //     if (newLimit === 15) {
  //       setCombinedState(prevState => ({
  //         ...prevState,
  //         limit: newLimit
  //       }));
  //     }
  //   }
  //   handleResize()
  //   window.addEventListener("resize", handleResize)
  //   window.removeEventListener("resize", handleResize)
  // }, [])
  const handleResize = () => {
    setIsMobile(window.innerWidth < 640);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   if (isMobile) {
  //     setCombinedState(prevState => ({
  //       ...prevState,
  //       search: null,
  //       company: [],
  //       category: [],
  //       limit: 10,
  //       page: currentPage,
  //     }));
  //     dispatch(clearResults())
  //     setSidebarCategory([]);
  //     setSidebarCompany([]);
  //   } else {
  //     setCombinedState(prevState => ({
  //       ...prevState,
  //       search: null,
  //       company: [],
  //       category: [],
  //       limit: 10,
  //       page: currentPage,
  //     }
  //     ));
  //     dispatch(clearResults())
  //     setSmFilter([]);
  //     setComFilter([]);
  //   }
  // }, [isMobile]);


  useEffect(() => {
    const hasChanges = JSON.stringify(combinedState.category) !== JSON.stringify(categoryBox) ||
      JSON.stringify(combinedState.company) !== JSON.stringify(companyBox) || combinedState.search !== getMainSearch;

    if (hasChanges) {
      setCombinedState((prevState) => ({
        ...prevState,
        search: getMainSearch,
        category: categoryBox,
        company: companyBox,
        page: 1,
      }));
      setSidebarCategory(categoryBox);
    }
  }, [categoryBox, companyBox, getMainSearch]);

  useEffect(() => {
    if (cat) {
      dispatch(clearResults());
      dispatch(setSelectedCategories([cat]));
    }
  }, [cat, dispatch]);

  useEffect(() => {
    if (company) {
      dispatch(clearResults());
      dispatch(addCompany(company))
    }
  }, [company, dispatch]);

  const [hasResults, setHasResults] = useState<boolean>(false);

  useEffect(() => {
    if (SearchProductsResponse) {
      setHasResults(true);
    } else {
      setHasResults(false);
    }
  }, [SearchProductsResponse]);

  const total = useMemo(() => Math.ceil(SearchProductsResponse?.total / 10), [SearchProductsResponse?.total]);

  const title = `${cat ? `${cat} Items` : company ? `${company} Items` : "Items"} |  Building Material Supplier | Chawla Ispat `;
  const description = "Browse items on Chawla Ispat's Items Page.";
  const canonicalUrl = chawlaispat;
  usePageMeta(title, description, canonicalUrl);

  const isLoadingState = isPriceLoading || isFetching
  return (
    <div className="font-inter w-full bg-white">
      <DetailsBar total={SearchProductsResponse?.total} limit={limit} isLoading={isPriceLoading} hasResults={hasResults} onClick={handleFilterButtonClick} />
      <div className="flex">
        <div className="w-full flex h-full gap-[1rem] sm:px-[0.67rem] mt-[1.3rem] pb-[32px] ">
          {/* filters */}
          <div className=" w-[170px] md:w-[270px] hidden sm:flex flex-col gap-[0.6rem] border-r-[2px] sticky top-[14rem] ">
            <ErrorBoundary >
              <Filters />
            </ErrorBoundary>
            <Companies />
          </div>

          {/* mobile filters */}
          {filters && (
            <div
              id="mobile-filter"
              style={{
                transform: `translateY(${Math.max(filterPosition, 0)}px)`,
                maxHeight: `calc(100vh - ${Math.max(filterPosition, 0)}px)`,
              }}
              className="fixed bg-white w-full border-t-[1px] rounded-t-[6px] z-50 sm:hidden bottom-0 flex flex-col justify-center gap-[0.6rem] border-r-[2px] m-0 p-0 overflow-y-auto"
            >
              <MobileFilter
                smallFilter={SmallFilter}
                smallCompanyFilter={SmallCompanyFilter}
                close={close}
                sidebarCategory={sidebarCategory}
                sidebarCompany={sidebarCompany}
              />
            </div>
          )}

          {/* data for cards */}
          <div className="flex flex-col  gap-[8px] sm:gap-[1rem] w-[92%] mx-auto">
            <div className="w-full border-b-2 ">
              {isLoadingState ? (
                <div className="flex justify-center h-[50vh] items-center ">
                  <RingLoader color="#5097A4" size={200} />
                </div>

              ) : SearchProductsResponse &&
                SearchProductsResponse?.products?.length > 0 ? (
                SearchProductsResponse?.products?.map(
                  (product: Products, index: number) => {
                    return (
                      <ItemCard
                        key={product?.id}
                        data={product}
                      />
                    )
                  }
                )
              ) : (
                <img src={noProductFound} className="h-200" alt="no products" />
              )}
            </div>
          </div>

        </div>
      </div>

      {getMainSearch &&
        <div className=" sm:pl-[170px] md:pl-[250px] w-full overflow-hidden " >
          <RelatedSearch search={getMainSearch} />
        </div>
      }

      {/* Pagination */}
      {SearchProductsResponse?.products?.length > 0 ? (
        <div className="h-[96px] flex justify-center items-center">
          <div>
            <Pagination
              total={total}
              current={currentPage}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      ) : null}

    </div>
  );
};

export default Items;




// import {useEffect, useMemo, useState } from "react";
// import ItemCard from "./Components/ItemCard";
// import { useLocation } from "react-router-dom";
// import { Filters } from "./Components/Filters";
// import Companies from "./Components/Companies";
// import noProductFound from "../../assets/noProduct.png"
// import { useSearchFiltersMutation } from "../../Redux/Api/ProductAPI";
// import { CombinedState } from "../../types/api-types";
// import Pagination from "./pagination";
// import { MobileFilter } from "./Components/MobileFilter";
// import { RingLoader, } from "react-spinners";
// import { useDispatch, useSelector } from "react-redux";
// import { RootState } from "../../Redux/store";
// import { addCompany, clearResults, resetPage, setCurrentPage, setSelectedCategories, } from "../../Redux/reducer/Filter";
// import { Products } from "./Components/types";
// import { DetailsBar } from "./Components/DetailsBar";
// import RelatedSearch from "./Components/RelatedSearch";
// import ErrorBoundary from "../../common/ErrorBoundaries";
// import { chawlaispat } from "../../Redux/apiRoutes";
// import usePageMeta from "../../common/hooks/usePageMeta";


// const Items = () => {
//   const location = useLocation();
//   const dispatch = useDispatch();
//   let cat = location.state ? location?.state?.id : null;
//   let company = location.state ? location?.state?.company : null;
//   const [sidebarCategory, setSidebarCategory] = useState<any>([]);
//   const [sidebarCompany, setSidebarCompany] = useState<any>([]);
//   const [searchFilters, { data: SearchProductsResponse, isLoading: isPriceLoading }] = useSearchFiltersMutation({},);
//   const currentPage = useSelector((state: RootState) => state.selection.currentPage);
//   const [isFetching, setIsFetching] = useState(false);
//   const [combinedState, setCombinedState] = useState<CombinedState>({
//     search: null,
//     company: [],
//     category: [],
//     limit: 10,
//     page: currentPage,
//   });

//   const [filters, setFilters] = useState<boolean>(false);
//   const [smFilter, setSmFilter] = useState<any>();
//   const [comFilter, setComFilter] = useState<any>();
//   const [filterPosition, setFilterPosition] = useState(0);
//   const [limit, setLimit] = useState<number>(6)

//   const categoryBox = useSelector((state: RootState) => state.selection.selectedCategories);
//   const companyBox = useSelector((state: RootState) => state.selection.selectedCompanies);
//   const getMainSearch = useSelector((state: RootState) => state.selection.selectedSearch);

//   useEffect(() => {
//     if (location.pathname === '/items') {
//       if (location.state?.fromSingleProduct) {
//       } else {
//         dispatch(resetPage());
//       }
//     }
//   }, [location, dispatch]);


//   const SmallFilter = (data: any) => {
//     setSmFilter(data);
//   };

//   const SmallCompanyFilter = (data: any) => {
//     setComFilter(data);
//   };

//   useEffect(() => {
//     if (smFilter || comFilter) {
//       setCombinedState((prevState) => ({
//         ...prevState,
//         search: null,
//         category: smFilter || prevState.category,
//         company: comFilter || prevState.company,
//         page: 1,
//       }));
//       if (smFilter) setSidebarCategory(smFilter);
//       if (comFilter) setSidebarCompany(comFilter)
//     }
//   }, [smFilter, comFilter]);



//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         await searchFilters({ combinedState });
//       } catch (error) {
//         console.log(error, "Error found");
//       }
//     };
//     fetchData();
//   }, [combinedState, searchFilters]);


//   // const fetchFilteredData = useCallback(
//   //   debounce(async (state) => {
//   //     setIsFetching(true);
//   //     try {
//   //       await searchFilters({ combinedState: state });
//   //     } catch (error) {
//   //       console.error("Error fetching data:", error);
//   //     } finally {
//   //       setIsFetching(false);
//   //     }
//   //   }, 300),
//   //   [searchFilters]
//   // );


//   // useEffect(() => {
//   //   if (combinedState && Object.keys(combinedState).length > 0) {
//   //     fetchFilteredData(combinedState);
//   //   }
//   // }, [combinedState, fetchFilteredData]);


//   useEffect(() => {
//     setIsFetching(true);
//     return () => {
//       setIsFetching(false);
//     };
//   }, []);


//   useEffect(() => {
//     setCombinedState((prevState) => ({
//       ...prevState,
//       page: currentPage,
//     }));
//   }, [currentPage]);

//   const onPageChange = (pageNumber: number) => {
//     dispatch(setCurrentPage(pageNumber));
//     setCombinedState((prevState) => ({
//       ...prevState,
//       page: pageNumber,
//     }));
//     window.scrollTo({ top: 0, behavior: "smooth" });
//   };

//   const close = (data: any) => {
//     setSidebarCategory([]);
//     setSidebarCompany([]);
//     setFilters(data);
//   };

//   const handleFilterButtonClick = () => {
//     setFilters(!filters);
//     if (!filters) {
//       setFilterPosition(0);
//     }
//   };


//   useEffect(() => {
//     const handleResize = () => {
//       const newLimit = window.innerWidth < 820 ? 15 : 6
//       setLimit(newLimit)
//       if (newLimit === 15) {
//         setCombinedState(prevState => ({
//           ...prevState,
//           limit: newLimit
//         }));
//       }
//     }
//     handleResize()
//     window.addEventListener("resize", handleResize)
//     window.removeEventListener("resize", handleResize)
//   }, [])


//   useEffect(() => {
//     const hasChanges = JSON.stringify(combinedState.category) !== JSON.stringify(categoryBox) ||
//       JSON.stringify(combinedState.company) !== JSON.stringify(companyBox) || combinedState.search !== getMainSearch;

//     if (hasChanges) {
//       setCombinedState((prevState) => ({
//         ...prevState,
//         search: getMainSearch,
//         category: categoryBox,
//         company: companyBox,
//         page: 1,
//       }));
//       setSidebarCategory(categoryBox);
//     }
//   }, [categoryBox, companyBox, getMainSearch]);

//   useEffect(() => {
//     if (cat) {
//       dispatch(clearResults());
//       dispatch(setSelectedCategories([cat]));
//     }
//   }, [cat, dispatch]);

//   useEffect(() => {
//     if (company) {
//       dispatch(clearResults());
//       dispatch(addCompany(company))
//     }
//   }, [company, dispatch]);

//   const [hasResults, setHasResults] = useState<boolean>(false);

//   useEffect(() => {
//     if (SearchProductsResponse) {
//       setHasResults(true);
//     } else {
//       setHasResults(false);
//     }
//   }, [SearchProductsResponse]);

//   const total = useMemo(() => Math.ceil(SearchProductsResponse?.total / 10), [SearchProductsResponse?.total]);

//   const title = `${cat ? `${cat} Items` : company ? `${company} Items` : "Items"} |  Building Material Supplier | Chawla Ispat `;
//   const description = "Browse items on Chawla Ispat's Items Page.";
//   const canonicalUrl = chawlaispat;
//   usePageMeta(title, description, canonicalUrl);

//   const isLoadingState = isPriceLoading;
//   return (
//     <div className="font-inter w-full bg-white">
//       <DetailsBar total={SearchProductsResponse?.total} limit={limit} isLoading={isPriceLoading} hasResults={hasResults} onClick={handleFilterButtonClick} />
//       <div className="flex">
//         <div className="w-full flex h-full gap-[1rem] sm:px-[0.67rem] mt-[1.3rem] pb-[32px] ">
//           {/* filters */}
//           <div className=" w-[170px] md:w-[270px] hidden sm:flex flex-col gap-[0.6rem] border-r-[2px] sticky top-[14rem] ">
//             <ErrorBoundary >
//               <Filters />
//             </ErrorBoundary>
//             <Companies />
//           </div>

//           {/* mobile filters */}
//           {filters && (
//             <div
//               id="mobile-filter"
//               style={{
//                 transform: `translateY(${Math.max(filterPosition, 0)}px)`,
//                 maxHeight: `calc(100vh - ${Math.max(filterPosition, 0)}px)`,
//               }}
//               className="fixed bg-white w-full border-t-[1px] rounded-t-[6px] z-50 sm:hidden bottom-0 flex flex-col justify-center gap-[0.6rem] border-r-[2px] m-0 p-0 overflow-y-auto"
//             >
//               <MobileFilter
//                 smallFilter={SmallFilter}
//                 smallCompanyFilter={SmallCompanyFilter}
//                 close={close}
//                 sidebarCategory={sidebarCategory}
//                 sidebarCompany={sidebarCompany}
//               />
//             </div>
//           )}

//           {/* data for cards */}
//           <div className="flex flex-col  gap-[8px] sm:gap-[1rem] w-[92%] mx-auto">
//             <div className="w-full border-b-2 ">
//               {isLoadingState ? (
//                 <div className="flex justify-center h-[50vh] items-center ">
//                   <RingLoader color="#5097A4" size={200} />
//                 </div>

//               ) : SearchProductsResponse &&
//                 SearchProductsResponse?.products?.length > 0 ? (
//                 SearchProductsResponse?.products?.map(
//                   (product: Products, index: number) => {
//                     return (
//                       <ItemCard
//                         key={product?.id}
//                         data={product}
//                       />
//                     )
//                   }
//                 )
//               ) : (
//                 <img src={noProductFound} className="h-200" alt="no products" />
//               )}
//             </div>
//           </div>

//         </div>
//       </div>

//       {getMainSearch &&
//         <div className=" sm:pl-[170px] md:pl-[250px] w-full overflow-hidden " >
//           <RelatedSearch search={getMainSearch} />
//         </div>
//       }

//       {/* Pagination */}
//       {SearchProductsResponse?.products?.length > 0 ? (
//         <div className="h-[96px] flex justify-center items-center">
//           <div>
//             <Pagination
//               total={total}
//               current={currentPage}
//               onPageChange={onPageChange}
//             />
//           </div>
//         </div>
//       ) : null}

//     </div>
//   );
// };

// export default Items;