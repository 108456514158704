import React from 'react'
import { BiHomeAlt2 } from "react-icons/bi";
import { RiBarChartBoxLine } from "react-icons/ri";
import { LuBuilding2, LuTruck } from "react-icons/lu";
import { MdErrorOutline } from "react-icons/md";
import { useLocation, useNavigate } from 'react-router-dom';
import { TbCategory2 } from 'react-icons/tb';
import { GoChecklist } from 'react-icons/go';
import { CgDatabase } from 'react-icons/cg';
import { useSelector } from 'react-redux';
import { RootState } from '../../Redux/store';

export const Navigation = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const current = location.pathname
  const userDetails = useSelector((state: RootState) => state.Login.ContractorData)
  return (
    <div className='w-full flex sm:hidden justify-between px-5 py-3 bg-white sticky bottom-0 left-0 right-0 z-30'>

      <div className='flex flex-col justify-center items-center' onClick={() => navigate("/")}>
        <div className={`bg-blue-500 h-1 w-1 rounded-full ${current === "/" ? "flex" : "hidden"}`}></div>
        <BiHomeAlt2 className={`w-[20px] h-[20px]  ${current == "/" ? "text-blue-500" : ""}`} />
        <span className={`font-[600] text-[10px] leading-[14px] ${current === "/" ? "text-blue-500" : ""}`}>Home</span>
      </div>


      <div className='flex flex-col justify-center items-center' onClick={() => navigate("/items")}>
        <div className={`bg-blue-500 h-1 w-1 rounded-full ${current === "/items" ? "flex" : "hidden"}`}></div>
        <RiBarChartBoxLine className={`w-[20px] h-[20px]  ${current == "/items" ? "text-blue-500" : ""}`} />
        <span className={`font-[600] text-[10px] leading-[14px]  ${current === "/items" ? "text-blue-500" : ""}`}>Products</span>
      </div>


      <div className='flex flex-col justify-center items-center' onClick={() => navigate("/company")}>
        <div className={`bg-blue-500 h-1 w-1 rounded-full ${current === "/company" ? "flex" : "hidden"}`}></div>
        <LuBuilding2 className={`w-[20px] h-[20px]  ${current == "/company" ? "text-blue-500" : ""}`} />

        <span className={`font-[600] text-[10px] leading-[14px]  ${current === "/company" ? "text-blue-500" : ""}`}>Company</span>
      </div>


      <div className='flex flex-col justify-center items-center gap-[5px]' onClick={() => navigate("/categories")}>
        <div className={`bg-blue-500 h-1 w-1 rounded-full ${current === "/categories" ? "flex" : "hidden"}`}></div>
        <TbCategory2 className={`w-[20px] h-[20px]  ${current == "/categories" ? "text-blue-500" : ""}`} />

        <span className={`font-[600] text-[10px] leading-[14px]  ${current === "/categories" ? "text-blue-500" : ""}`}>Category</span>
      </div>
      {
        userDetails && <div className='flex flex-col justify-center items-center gap-[5px]' onClick={() => navigate("/profile/quotation-tracker")}>
          <div className={`bg-blue-500 h-1 w-1 rounded-full ${current === "/profile/quotation-tracker" ? "flex" : "hidden"}`}></div>
          <CgDatabase className={`w-[20px] h-[20px]  ${current == "/profile/quotation-tracker" ? "text-blue-500" : ""}`} />

          <span className={`font-[600] text-[10px] leading-[14px]  ${current === "/profile/quotation-tracker" ? "text-blue-500" : ""}`}>Query Tracker</span>
        </div>
      }


    </div>
  )
}
