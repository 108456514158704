import DiscountCard from "./Components/DiscountCard";
import ShopWithUs from "./Components/ShopWithUs";
import Carousel from "./Components/Carousel";
import HomeVideo from "../assets/video2.png";
import cation from "../assets/caption.png";
import Comp1 from "./extra/Comp1";
import { useNavigate } from "react-router-dom";
import video from "../assets/smallposter.png";
import FirstCategory from "./Components/FirstCategory";
import SecondCategory from "./Components/SecondCategory";
import CategorySecond from "./Components/CategorySecond";
import ImgCard from "./Components/ImgCard";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/store";
import Explore from "./Components/Explore";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { chawlaispat } from "../Redux/apiRoutes";
import usePageMeta from "../common/hooks/usePageMeta";
import HomeImageSection from "./Components/HomeImageSection";
import TurnOver from "./Components/turnOver";
import { useGetCarouselDataMutation } from "../Redux/Api/Category";
import { t } from "i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface Data {
  id: string;
  image: string;
  itemCount: number;
  name: string;
}

const Home = () => {
  const navigate = useNavigate();
  const allCategories = useSelector(
    (state: RootState) => state.values.allCategories
  );


  const [mutation, { data, isLoading }] = useGetCarouselDataMutation();
  const [slides, setSlides] = useState<JSX.Element[]>([]);
  useEffect(() => {
    const storedCategoryIds = sessionStorage.getItem("categoryIds");
    const parsedCategoryIds = storedCategoryIds
      ? JSON.parse(storedCategoryIds)
      : [];
    if (parsedCategoryIds.length === 0) {
      mutation({ category: parsedCategoryIds });
    } else {
      mutation({ category: parsedCategoryIds });
    }
  }, []);
  useEffect(() => {
    if (data && data.data) {
      const categoryIdsFromData = data.data.map((item: any) => item.id);
      const storedCategoryIds = sessionStorage.getItem("categoryIds");
      const parsedCategoryIds = storedCategoryIds
        ? JSON.parse(storedCategoryIds)
        : [];
      if (parsedCategoryIds.length === 0) {
        sessionStorage.setItem(
          "categoryIds",
          JSON.stringify(categoryIdsFromData)
        );
      }

      const generatedSlides = data.data.map((item: any, index: number) => (
        <Comp1 key={index} categoryId={item} isLoading={isLoading} />
      ));
      setSlides(generatedSlides);

    }
  }, [data]);

  // useEffect(() => {
  //   i18n.changeLanguage(currentLanguage);
  // }, [currentLanguage, i18n]);

  const title = "Home | Building Material Supplier | Chawla Ispat ";
  const description =
    "Chawla Ispat is a Leading building material supplier with a range of products. Your trusted partner among construction material suppliers";
  const baseUrl = chawlaispat;

  usePageMeta(title, description, baseUrl);

  const settings = {
    dots: false, // No dots under the slider
    infinite: true, // Enable infinite scrolling
    speed: 500, // Transition speed
    slidesToShow: 2, // Show 2 cards at a time
    slidesToScroll: 1, // Scroll 1 card at a time
    arrows: true, // Enable inbuilt arrows
    centerMode: true, // Center the slides
    centerPadding: "10px", // Add slight padding for better centering
    responsive: [
      {
        breakpoint: 640, // Mobile screen adjustments
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: "0px", // Remove extra padding on mobile
        },
      },
    ],
  };




  return (
    <div className="bg-slate-50">
      <div className="relative mx-auto w-full lg:w-[90%]">
        <div className="h-[34rem] sm:h-[24rem] md:h-[28rem] lg:h-[35rem]">
          <Carousel slides={slides} />
        </div>
      </div>

      <div className="flex flex-col md:flex-row -mt-[7rem] sm:-mt-[0.76rem] md:-mt-[2.14rem] lg:-mt-[3.5rem] items-center justify-center flex-wrap gap-6 mx-auto top-0 overflow-hidden relative z-30">
        <div className="relative flex flex-col lg:flex-row gap-6 justify-center">
          <div className="flex flex-col sm:flex-row gap-6 justify-center relative z-10">
            {/* Category Card */}
            <div className="p-3 mt-28 sm:p-1 md:p-5 sm:mt-2 sm:w-[20rem] md:w-[23rem] z-20 sm:bg-white">
              <div className="font-inter font-bold text-md pb-2">
                {t("home.topCategory")}
              </div>
              <div className="  ">
                <div className="w-full sm:hidden max-w-[19.5rem] px-3 mx-auto py-2">
                  {allCategories && (
                    <Slider {...settings}>
                      {allCategories.slice(0, 10).map((item: Data) => (
                        <div key={item.id} className="pl-1.5 mx-auto py-2">
                          <ImgCard
                            id={item.id}
                            data={item}
                            smallScreen={true}
                            largeScreen={false}
                          />
                        </div>
                      ))}
                    </Slider>
                  )}
                </div>


              </div>
              {/* <div className="flex sm:hidden gap-[8px] overflow-x-auto w-[21rem] ">
                {allCategories &&
                  allCategories?.slice(0, 10).map((item: Data) => (
                    <div className="w-[104px]" key={item?.id}>
                      <ImgCard
                        id={item?.id}
                        data={item}
                        smallScreen={true}
                        largeScreen={false}
                      />
                    </div>
                  ))}
              </div> */}
              <div className="hidden sm:flex flex-wrap justify-between  gap-2 mt-4 ">
                {allCategories &&
                  allCategories
                    ?.slice(0, 4)
                    .map((item: Data) => (
                      <ImgCard
                        key={item?.id}
                        id={item?.id}
                        data={item}
                        smallScreen={false}
                        largeScreen={true}
                      />
                    ))}
              </div>
            </div>
            <DiscountCard data={allCategories?.slice(0, 10)} />
          </div>

          <div className="flex flex-col sm:flex-row lg:flex-col gap-3 relative z-10">
            <ShopWithUs />
            <div className="w-[350px] mx-auto ">
              <img
                src={allCategories ? allCategories[12]?.image : ""}
                alt="home"
                className="h-[17rem] lg:h-[23rem] w-full z-10 cursor-pointer object-cover"
                onClick={() =>
                  navigate("/items", { state: { id: allCategories[12]?.id } })
                }
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col bg-slate-50 gap-7 my-[16px]">
          <div className="mx-auto xl:w-[75%] w-[95%] md:w-[85%]">
            <TurnOver />
          </div>

          <div className="flex flex-col justify-center md:flex-row gap-6">
            <div className="flex flex-col">
              <div className="flex items-center justify-center md:hidden">
                <img
                  src={video}
                  alt="video"
                  className="h-[8rem] sm:h-[12rem]"
                />
              </div>
              <div className="hidden md:flex relative">
                <img
                  src={HomeVideo}
                  alt="homevideo"
                  className="h-[17rem] lg:h-[23rem] z-10"
                />
                <img
                  src={cation}
                  alt="caption"
                  className="absolute top-0 left-0 h-[23rem] z-20"
                />
              </div>
            </div>
            <div className="flex justify-center">
              <CategorySecond data={allCategories?.slice(0, 10)} />
            </div>

          </div>
          <FirstCategory data={allCategories?.slice(0, 10)} />
          <Explore />
          <HomeImageSection />
          <SecondCategory data={allCategories?.slice(0, 10)} />
        </div>
      </div>
    </div>
  );
};

export default Home;
