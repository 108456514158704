import { useCallback, useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { GoArrowLeft } from "react-icons/go";
import { GoArrowUpLeft } from "react-icons/go";
import image from "../assets/Bangur.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useCreateSearchQueryMutation, useGetTopSearchesQuery, usePriceMutation, useSearchDataMutation } from "../Redux/Api/priceAPI";
import {
  clearSubResults,
  setSearch,
  setSubSearch,
} from "../Redux/reducer/Filter";
import { useTopSellingQuery } from "../Redux/Api/top";
import { chawlaispat } from "../Redux/apiRoutes";
import usePageMeta from "./hooks/usePageMeta";
import { MdOutlineSearch } from "react-icons/md";
import useDebounce from "./hooks/useDebounce";
import { IoSearchSharp } from "react-icons/io5";
import { SearchQuery } from "../screens/Components/types";
import { HiMiniArrowTrendingUp } from "react-icons/hi2";

interface Selling {
  image: any;
  productName?: string;
  id?: string;
  type: string;
  view: number;
}

interface Price {
  id: string;
  finalPrice: number;
  unit: string;
}

interface PriceResponse {
  productId: string;
  finalPrice: number;
  unit: string;
}

export const SearchPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, getSearch] = useState<string>("");
  const [searchResults, setSearchResults] = useState<Selling[]>([]);
  const [searchAllProducts, { data: searchResponse, isLoading }] = useSearchDataMutation();
  const [createSearchQuery,] = useCreateSearchQueryMutation();
  const { data: selling } = useTopSellingQuery();
  const { data: queryData } = useGetTopSearchesQuery();
  const [priceMutation] = usePriceMutation();
  const [data, setData] = useState([]);
  const [priceData, setPriceData] = useState<{ [key: string]: Price }>({});

  const handleSearch = (value: string) => {
    getSearch(value);
  };

  const searchDebounce = useDebounce(search, 500);

  useEffect(() => {
    if (searchResponse) {
      setSearchResults(searchResponse);
    } else {
      setSearchResults([]);
    }
  }, [searchResponse]);


  const memoizedSearchAllProducts = useCallback(
    async (searchDebounce: string) => {
      if (!searchDebounce.trim()) {
        setSearchResults([]);
        return;
      }
      try {
        const response = await searchAllProducts(searchDebounce);
        if (!response?.data) {
          console.error("Error in search response:", response.error);
        }
      } catch (error) {
        console.error("Error searching products:", error);
      }
    },
    [searchAllProducts]
  );

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchDebounce.trim() !== "") {
        await memoizedSearchAllProducts(searchDebounce);
      } else {
        setSearchResults([]);
      }
    };
    fetchSearchResults();
  }, [searchDebounce, memoizedSearchAllProducts]);


  const showSearchBoxOnClick = (event: any) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      dispatch(setSearch(search));
      navigate(`/items`);
      if (search.trim() !== "") createSearchQuery({ query: search })
    }
  };

  const onGlassClick = () => {
    if (search.trim() !== "") {
      dispatch(setSearch(search));
      navigate(`/items`);
      createSearchQuery({ query: search })
    }
  };
  const handleTopSearchesClick = (recentSerach: string) => {
    if (recentSerach.trim() !== "") {
      dispatch(setSearch(recentSerach));
      navigate(`/items`);
      createSearchQuery({ query: recentSerach })
    }
  };

  const handleNavigation = (name: string) => {
    navigate("/subItems");
    dispatch(clearSubResults());
    dispatch(setSubSearch(name));
  };

  useEffect(() => {
    if (selling) {
      setData(selling);
    }
  }, [selling]);

  useEffect(() => {
    const fetchPrices = async (productId: string[]) => {
      try {
        const priceResponse = await priceMutation({ productId });
        const priceDataObj: { [key: string]: Price } = {};
        priceResponse?.data?.results
          ?.slice(0, 8)
          .forEach((result: PriceResponse) => {
            priceDataObj[result.productId] = {
              id: result.productId,
              finalPrice: result.finalPrice,
              unit: result.unit,
            };
          });
        setPriceData(priceDataObj);
      } catch (error) {
        console.log(error, "error occured");
      }
    };

    if (selling?.length > 0) {
      const productId = selling.map((selling: Selling) => selling.id);
      fetchPrices(productId);
    }
  }, [selling, priceMutation]);


  const title = "Search | Building Material Supplier | Chawla Ispat";
  const description = "Explore search results and find products or information quickly on the Search Page of Chawla Ispat.";
  const canonicalUrl = chawlaispat;

  usePageMeta(title, description, canonicalUrl);

  const handleItemClick = (index: number, name: string) => {
    if ([0, 1, 2].includes(index)) {
      onGlassClick();
    } else if (index >= 3) {
      handleNavigation(name);
    }
  };

  const renderIcon = (index: number) => {
    return index >= 3 ? (
      <GoArrowUpLeft fontSize="1.10rem" />
    ) : (
      <MdOutlineSearch fontSize="1.10rem" color="#5097A4" />
    );
  };



  const highlightSearchTerm = (text: string, searchTerm: string) => {
    const regex = new RegExp(`(${searchTerm})`, "gi");
    let matchCount = 0;

    return text.split(regex).map((part, index) => {
      if (part.toLowerCase() === searchTerm.toLowerCase() && matchCount < 3) {
        matchCount++;
        return (
          <span key={index} className="bg-[#5097A4] text-white">
            {part}
          </span>
        );
      }
      return <span key={index} className="text-black">{part}</span>;
    });
  };


  return (
    <div className="  my-1">
      <div className=" flex gap-2 items-center   w-full px-[4px]">
        <GoArrowLeft
          className="h-8 w-8 cursor-pointer"
          onClick={() => navigate("/")}
        />

        <div className="px-2 py-2 my-1 rounded-3xl flex items-center border-[1.5px] border-[#5097A4] w-full">
          <input
            className="w-full pl-2 border-none outline-none text-gray-500 text-sm"
            placeholder="Search Here..."
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            onKeyDown={(e) => showSearchBoxOnClick(e)}
          />
          <IoSearchSharp
            className="h-5 w-5 cursor-pointer font-bold text-[#5097A4]  "
            onClick={onGlassClick}
          />
        </div>
      </div>

      <div className="mt-2 border-t border-gray-300 h-[192px] overflow-y-auto shadow-sm rounded-b-lg">
        {!search && (
          <>
            <div className="p-1   text-xs cursor-pointer mx-1 my-1 rounded-sm">
              <div className="flex items-center px-1 ">
                <span className="font-inter font-medium text-sm leading-4 text-wrap text-gray-900 flex items-center gap-2">
                  Top Searches <HiMiniArrowTrendingUp fontSize={'1.10rem'} />
                </span>
              </div>
            </div>
            {
              queryData && queryData?.topSearches?.slice(0, 5)?.map((searchItem: SearchQuery, i: number) => (
                <div key={i} className="p-1 border-[0.4px] hover:bg-slate-200 text-xs cursor-pointer mx-1 my-1 rounded-sm"
                  onClick={() => handleTopSearchesClick(searchItem?.query)}
                >
                  <div className="flex items-center px-1 gap-4">
                    <span className="w-4 ">
                      <MdOutlineSearch fontSize="1.10rem" color="#5097A4" />
                    </span>
                    <span className="font-inter font-normal text-xs leading-4 text-wrap  ">
                      <span className="font-inter font-normal text-xs leading-4 text-wrap ">
                        <span className=" font-bold  text-gray-700  tracking-wider">{searchItem?.query}</span>
                      </span>
                    </span>

                  </div>
                </div>
              ))}

          </>
        )}

        {/* Search Entry */}
        {search && (
          <div className="p-1 border-[0.4px] hover:bg-slate-200 text-xs cursor-pointer mx-1 my-1"
            onClick={() => onGlassClick()}
          >
            <div className="flex items-center px-1 gap-4">
              <span className="w-4 ">
                <MdOutlineSearch fontSize="1.10rem" color="#5097A4" />
              </span>
              <span className="font-inter font-normal text-xs leading-4 text-wrap  ">
                <span className="font-inter font-normal text-xs leading-4 text-wrap ">
                  <span className=" font-bold  bg-[#5097A4] text-white tracking-wider">{search}</span>
                </span>
              </span>

            </div>
          </div>
        )}

        {/* Search Results, Loading, or No Data Message */}
        {isLoading ? (
          <div className="w-full h-[200px] flex justify-center items-start mt-14">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
          </div>
        ) : searchResults.length > 0 ? (
          searchResults.slice(0, 7).map((searches: any, index: number) => (
            <div
              className="p-1 border hover:bg-slate-200 text-xs cursor-pointer mx-1 my-1 rounded-sm"
              onClick={() => handleItemClick(index, searches?.name)}
              key={index}
            >
              <div className="flex items-center px-1 gap-4">
                <span className="w-4 ">{renderIcon(index)}</span>
                <span className="font-inter font-normal text-xs leading-5 tracking-wide">
                  {[0, 1, 2].includes(
                    index
                  )
                    ? highlightSearchTerm(
                      (searches.itemName || "").slice(0, 30) +
                      (searches.itemName?.length > 30 ? "..." : ""),
                      search
                    )
                    :
                    <span className="text-black">
                      {searches.name.slice(0, 40) + (searches.name.length > 40 ? "..." : "")}
                    </span>
                  }
                </span>

              </div>
            </div>
          ))
        ) : (
          search && (
            <div className="w-full h-[200px] flex justify-center items-start mt-16">
              <div className="text-gray-600 text-sm font-inter font-medium">
                No data, try again
              </div>
            </div>
          )
        )}
      </div>

      <div className="p-2 flex flex-col gap-2">
        {data && <div className=" text-lg my-2 font-bold text-center">Popular Products</div>}

        <div className=" flex flex-wrap justify-evenly gap-[12px]">
          {data &&
            data
              ?.slice(0, 7)
              .map((item: Selling, index: number) => {
                if (
                  item?.id &&
                  item.id in priceData &&
                  priceData[item?.id]?.id === item?.id
                ) {
                  const PriceData = priceData[item?.id];
                  const totalPrice = PriceData?.finalPrice;
                  const unit = PriceData?.unit;
                  return (
                    <div
                      className="w-[120px] border  rounded-xl flex flex-col gap-[10px] py-2 px-2 group hover:scale-105 duration-300"
                      key={item.id}
                      style={{
                        boxShadow:
                          "0px 1px 4.7px 0px #00000040",
                      }}>
                      <img
                        src={
                          item?.image.length > 0
                            ? item?.image[0]
                            : image
                        }
                        alt="imagse"
                        className="h-[100px]  rounded-xl object-cover object-bottom  "
                      />
                      <div className="flex flex-col justify-center items-center text-xs mt-[-2px] ">
                        <span
                          className="line-clamp-3 w-full cursor-pointer text-[#696161] font-medium text-center"
                          onClick={() =>
                            navigate(
                              `/singleproduct/${item.id}`,
                              {
                                state: {
                                  id: item?.id,
                                },
                              }
                            )
                          }>
                          {item?.productName &&
                            item?.productName?.split(
                              " "
                            ).length > 4
                            ? `${item.productName
                              .split(" ")
                              .slice(0, 4)
                              .join(" ")}...`
                            : item?.productName}

                          {/* {item?.productName} */}
                        </span>
                        {/* text-[#009DFF] */}
                        <span className="truncate w-full flex justify-center  cursor-pointer font-bold  text-[#31B42E]">
                          {totalPrice
                            ? `${totalPrice.toFixed(
                              2
                            )} / ${unit}`
                            : ""}
                        </span>
                      </div>
                      <div className="mt-auto flex items-end gap-1 justify-start text-[#CC0C39] text-[8px] mb-1">
                        <span className="py-[1px] px-[3px] bg-[#5097A4] text-white text-nowrap font-bold rounded-sm">
                          {index === 0
                            ? "27% Off"
                            : index === 1
                              ? "28% Off"
                              : index === 2
                                ? "22% Off"
                                : index === 3
                                  ? "25% Off"
                                  : index === 4
                                    ? "27% Off"
                                    : index === 5 ||
                                      index === 6
                                      ? "30% Off"
                                      : ""}
                        </span>
                        <span className="text-[8px] font-medium">
                          Special Offer
                        </span>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
        </div>
      </div>
    </div>
  );
};


