import { useState, useEffect } from "react";
import { CombinedState } from "../../../types/api-types";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { useSearchFiltersMutation } from "../../../Redux/Api/ProductAPI";
import AssignedCategoryItemCard from "../components/AssignedCategoryItemCard";
import { RingLoader } from "react-spinners";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { RiArrowLeftSLine } from "react-icons/ri";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const AssignedCategoryItem = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setPerPageLimit] = useState(6);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
    const [searchFilters, { data: itemsData, isLoading: isProductLoading }] = useSearchFiltersMutation();
    const { category } = useSelector((state: RootState) => state.assignedRewards);
    const [storedCategory, setStoredCategory] = useState(() => {
        const savedCategory = localStorage.getItem("assignedCategory");
        return savedCategory ? JSON.parse(savedCategory) : category;
    });
    const categoryId = storedCategory?.id;
    const navigate = useNavigate()
    const [combinedState, setCombinedState] = useState<CombinedState>({
        search: null,
        company: [],
        category: categoryId ? [categoryId] : [],
        limit: itemsPerPage,
        page: currentPage,
    });


    useEffect(() => {
        if (category) {
            localStorage.setItem("assignedCategory", JSON.stringify(category));
        }
    }, [category]);


    useEffect(() => {
        if (combinedState?.category?.length === 0) return;

        const payload = {
            page: currentPage,
            combinedState: {
                ...combinedState,
                limit: itemsPerPage,
            },
        };

        const fetchData = async () => {
            try {
                await searchFilters(payload);
            } catch (error) {
                console.error("Error found", error);
            }
        };

        fetchData();
    }, [combinedState, currentPage, itemsPerPage, searchFilters]);



    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
        setCombinedState((prevState) => ({
            ...prevState,
            page: newPage,
        }));
    };

    const totalPages = Math.ceil((itemsData?.total || 0) / itemsPerPage);


    const handleResize = () => {
        setIsMobile(window.innerWidth < 640);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (isMobile) {
            setPerPageLimit(10);
        } else {
            setPerPageLimit(6);
        }
    }, [isMobile]);


    if (isProductLoading) {
        return (
            <div className="flex justify-center items-center h-[50vh]">
                <RingLoader color="#5097A4" size={150} />
            </div>
        );
    }


    const handleBackNavigation = () => {
        navigate("/assignrewards")
    }


    return (
        <div className="font-inter bg-white w-full h-auto relative">
            <div className="pt-4 w-11/12 m-auto">
                <button className="   bg-[#5097A4]  py-2 px-3 rounded-full h-10 w-10 z-40 flex justify-center items-center text-white  " onClick={handleBackNavigation}><FaArrowLeft /></button>
            </div>
            <div className=" p-6 w-11/12 m-auto ">
                <div className="flex  flex-col sm:flex-row sm:justify-between gap-4">
                    <div className="font-[700] text-[25px] sm:text-[32px] leading-[38px]"   >
                        Assigned Category Item
                    </div>
                    <div className="border-2 border-[#5097A4] rounded-sm py-2 px-4 flex justify-center items-center w-full sm:w-auto">
                        <span className="font-[600] text-[16px] leading-[20px]">
                            Category : <span className="text-[#5097A4]">{storedCategory?.name}</span>
                        </span>
                    </div>
                </div>
                <div className="grid grid-cols-1 2xl:grid-cols-2 gap-6 mt-10  transition-all duration-500 ease-in-out transform ">
                    {itemsData?.products?.map((product: any) => (
                        <AssignedCategoryItemCard
                            key={product.id}
                            product={product}
                        />
                    ))}
                </div>
                <div className="flex justify-center my-4 text-[#5097A4] text-sm font-medium items-center mt-5">
                    <button
                        onClick={() =>
                            handlePageChange(currentPage - 1)
                        }
                        disabled={currentPage === 1}
                        className="px-4 py-2 sm:ml-2   disabled:text-gray-300 flex items-center">
                        <RiArrowLeftSLine className="h-8 w-8" />{" "}
                        Previous
                    </button>
                    <span className=" flex  items-center justify-center space-x-3 text-gray-700">
                        <span>Page</span>{" "}
                        <span className="px-2 py-1 bg-gray-200 rounded-md">
                            {currentPage}{" "}
                        </span>{" "}
                        <span>of</span>{" "}
                        <span className="px-2 py-1 bg-gray-200 rounded-md">
                            {totalPages}
                        </span>
                    </span>
                    <button
                        onClick={() =>
                            handlePageChange(currentPage + 1)
                        }
                        disabled={currentPage === totalPages}
                        className="px-4 py-2 ml-2   disabled:text-gray-300 flex items-center">
                        Next{" "}
                        <MdOutlineKeyboardArrowRight className="h-8 w-8" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AssignedCategoryItem;
