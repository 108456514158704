import React from 'react';

interface FilterOptionProps {
    condition: string;
    isActive: boolean;
    onClick: (condition: string) => void;
    count: any

}

const QuotationTabs = ({ condition, isActive, onClick, count }: FilterOptionProps) => {
    return (
        <span
            onClick={() => onClick(condition)}
            role="button"
            tabIndex={0}
            className={`py-1 px-4 sm:px-10 lg:px-5 font-medium w-full text-center text-[14px] cursor-pointer rounded-md transition-all duration-200 ease-in-out text-nowrap flex justify-center gap-1 capitalize ${isActive
                ? "text-white bg-[#5097A4]"
                : "text-gray-600 hover:text-[#5097A4] bg-transparent"
                }`}
        >
            {`${condition} (${count})`}
        </span>
    );
};

export default QuotationTabs;
