import { ChangeEvent, useEffect, useState } from "react";
import { useGetContractorCoinHistoryMutation } from "../../../Redux/Api/Order";
import useDebounce from "../../../common/hooks/useDebounce";
import { IOrderData } from "./profileTyps";
import CoinHistoryTabs from "./CoinHistoryComponents/CoinHistoryTabs";
import CoinHistoryFilters from "./CoinHistoryComponents/CoinHistoryFilters";
import ProfilePagesLoading from "./ProfilePagesLoading";
import ProfileNoData from "./ProfileNoData";
import CoinHistoryData from "./CoinHistoryComponents/CoinHistoryData";

const ContractorCoinHistory = () => {
	const [coins, setCoins] = useState<string>("All");
	const [orderNumber, setOrderNumber] = useState<string>("");
	const [selectedDate, setSelectedDate] = useState<string>("");
	const [mutation, { data: orderData, isLoading }] =
		useGetContractorCoinHistoryMutation();
	const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
	const [awardedBonus, setAwardedBonus] = useState<number | null>(null);

	const debounceDate = useDebounce(selectedDate, 300);
	const debounceOrderNumber = useDebounce(orderNumber, 500);
	useEffect(() => {
		if (coins || debounceDate || debounceOrderNumber) {
			mutation({
				condition: coins?.toLowerCase(),
				...(debounceDate && { date: debounceDate }),
				...(debounceOrderNumber && {
					orderNumber: debounceOrderNumber,
				}),
			})
				.unwrap()
				.catch((error: any) => {
					console.error(
						"Error fetching contractor coin history:",
						error
					);
				});
		}
	}, [coins, debounceDate, debounceOrderNumber, mutation]);

	const toggleExpanded = (index: number) => {
		setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
	};

	const handleCoinApi = (selectedData: string) => {
		setCoins(selectedData);
		mutation({ condition: selectedData.toLowerCase() });
	};

	const handleAwardBonus = (bonus: number) => {
		setAwardedBonus(bonus);
		setTimeout(() => setAwardedBonus(null), 2000);
	};

	const getNoDataMessage = () => {
		if (coins === "All") return "No coins allocated yet !";
		if (coins === "Credited") return "No coins credited yet !";
		if (coins === "Debited") return "No coins debited yet !";
	};

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = e.target;
		if (name === "selectedDate") {
			setSelectedDate(value);  
		} else if (name === "orderNumber") {
			setOrderNumber(value.trim());  
		}
	};

	const resetFilters = () => [setSelectedDate(""), setOrderNumber("")];
	return (
		<div className="profile-container">
			<CoinHistoryTabs coins={coins} handleCoinApi={handleCoinApi} />
			<div className=" flex flex-col sm:flex-row gap-2 items-center justify-end mb-4">
				<CoinHistoryFilters
					selectedDate={selectedDate}
					orderNumber={orderNumber}
					handleInputChange={handleInputChange}
					resetFilters={resetFilters}
				/>
			</div>
			<section className="min-h-screen">
				{isLoading ? (
					<ProfilePagesLoading />
				) : orderData?.data?.length > 0 ? (
					orderData?.data?.map((item: IOrderData, index: number) => {
						const isExpanded = expandedIndex === index;
						return (
							<CoinHistoryData
								key={index}
								item={item}
								toggleExpanded={toggleExpanded}
								isExpanded={isExpanded}
								index={index}
								handleAwardBonus={handleAwardBonus}
								awardedBonus={awardedBonus}
							/>
						);
					})
				) : (
					<ProfileNoData text={getNoDataMessage()} />
				)}
			</section>
		</div>
	);
};

export default ContractorCoinHistory;
