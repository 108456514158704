import { memo } from "react";
import defaultImage from "../../assets/defaultImage.svg";
import { useNavigate } from "react-router-dom";
import { BsTelephone } from "react-icons/bs";
import { Category } from "../Components/types";

interface CategoryCardProps {
  category: Category;
}


const CategoryCard = memo(({ category }: CategoryCardProps) => {
  const navigate = useNavigate();

  const handleItemClick = (itemName: any) => {
    navigate("/items", { state: { id: itemName } });
  };


  const handleWhatsappClick = () => {
    const phoneNumber = "919258294692";
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };

  return (
    <>
      <div
        className="bg-white hidden sm:flex flex-col justify-between rounded-lg hover:shadow-2xl hover:shadow-[#5097A4] gap-3 p-[12px] cursor-pointer border w-[16rem]
       h-[280px] sm:h-[348px]"

      >
        <div onClick={() => handleItemClick(category.id)} className="w-[232px] h-[144px] flex justify-center mx-auto rounded-lg  sm:rounded-none   overflow-hidden group border">

          <img
            className="relative md:h-[14rem] h-[11.1rem] w-[17rem] py-1 group-hover:scale-110 transition-transform duration-300"
            src={
              category?.imageCarouselDetails && category.imageCarouselDetails.length > 0 && category.imageCarouselDetails[0]?.image
                ? category.imageCarouselDetails[0].image
                : defaultImage
            }
            alt="categoryimage"
          />
        </div >
        <div onClick={() => handleItemClick(category.id)} className="flex flex-col justify-between ">
          <div className="text-lg font-bold leading-[28px]  flex overflow-hidden">{category.name}</div>
          <div className="text-gray-600 ">Item {category.itemCount}</div>

        </div>

        <div onClick={handleWhatsappClick} className=" relative w-full h-11 group">
          {/* Default state */}
          <div className="w-full h-full px-3 py-3 flex whitespace-nowrap bg-white rounded-lg border border-slate-200  justify-center gap-3 items-center transition-opacity duration-300 absolute inset-0 group-hover:opacity-0">
            <div className="text-[#5097A4] flex whitespace-nowrap text-sm font-semibold font-['Inter'] leading-tight">
              Contact For Better Pricing
            </div>
            <BsTelephone className="w-4 h-4 relative text-[#5097A4]" />
          </div>
          {/* Hover state */}
          <div className="w-full h-full px-4 py-3 bg-[#5097A4] rounded-lg shadow flex items-center gap-3 justify-center transition-all duration-300 opacity-0 group-hover:opacity-100 group-hover:shadow-2xl absolute inset-0 overflow-hidden">
            <BsTelephone className="w-4 h-4 relative text-white opacity-0 transition-opacity duration-100 delay-300 group-hover:opacity-100" />
            <div className="text-white text-sm flex whitespace-nowrap font-semibold font-['Inter'] leading-tight transition-transform duration-300 transform translate-x-full group-hover:translate-x-0">
              Contact For Better Pricing
            </div>
          </div>
        </div>



      </div>
      <div className=" block my-1 sm:hidden w-full">
        <div onClick={() => handleItemClick(category?.id)} className="relative flex w-full items-center overflow-hidden rounded-lg bg-white p-2 shadow hover:shadow-md border cursor-pointer">
          <div className="shrink-0 h-28 w-28  bg-gray-100 border rounded-lg overflow-hidden flex items-center justify-center" >
            <img src={
              category?.imageCarouselDetails && category.imageCarouselDetails.length > 0 && category.imageCarouselDetails[0]?.image
                ? category.imageCarouselDetails[0].image
                : defaultImage
            } alt="" className="object-contain object-center w-full h-full rounded-lg " />
          </div>
          <div className="ml-3 w-full space-y-2">
            <p className="font-medium text-gray-800">
              {category?.name?.length > 15 ? `${category.name.substring(0, 15).trim()}...` : category?.name}
            </p>

            <p className="text-sm text-gray-600">category {category?.companyCount}</p>
            <button
              onClick={() => handleItemClick(category?.id)}
              className="flex items-center justify-center px-3 py-2 text-sm font-medium text-cente transition-all duration-300 group text-turkishBlue-default  ml-auto hover:bg-turkishBlue-default rounded-md hover:text-white "
            >
              See more
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2 transform transition-transform duration-300 animate-arrowLoop group-hover:animate-none"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>



    </>
  );
});

export default CategoryCard;