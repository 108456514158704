import CategoryCard from "./categorycard";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Category } from "../Components/types";
import { chawlaispat } from "../../Redux/apiRoutes";
import usePageMeta from "../../common/hooks/usePageMeta";
import { IoIosSearch } from "react-icons/io";
import { RingLoader } from "react-spinners";
import NoData from "../../common/NoData";
import { useAllCategoriesQuery } from "../../Redux/Api/Category";
import useDebounce from "../../common/hooks/useDebounce";

const CategoryPage = () => {
  const [search, setSearch] = useState<string>("")
  const [categoriesLoading, setCategoriesLoading] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSearchClick = () => {
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };


  const debounceCategoriesSearch = useDebounce(search, 400)
  const { data: categoriesData, isFetching: categoriesFetching, } = useAllCategoriesQuery(
    debounceCategoriesSearch ? { search: debounceCategoriesSearch } : undefined,
    {
      refetchOnMountOrArgChange: true,

    }
  );

  const onChnageHandler = (e: ChangeEvent<HTMLInputElement>) => {setSearch(e.target.value)}

  useEffect(() => {
    if (categoriesFetching) {
      setCategoriesLoading(true)
    } else {
    setCategoriesLoading(false)
    }
  }, [categoriesFetching]);



  const title = "Categories | Building Material Supplier | Chawla Ispat";
  const description = "Browse various categories on Chawla Ispat to find what you need.";
  const canonicalUrl = chawlaispat;

  usePageMeta(title, description, canonicalUrl);

  return (
    <div className="font-inter bg-white">
      <div className="py-12 w-11/12 m-auto ">
        <div className="flex justify-between flex-col sm:flex-row">
          <div className="font-[700] text-[25px] sm:text-[32px] leading-[38px]">
            All Category
          </div>
          <div className="relative sm:mr-6 my-2 border rounded-md ">
            <input
              type="text"
              ref={inputRef}
              className="input-style"
              placeholder="Search"
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onChange={onChnageHandler}
              value={search}
            />
            {!isFocused && (
              <span
                onClick={handleSearchClick}
                className={`absolute right-3 top-1/2 transform -translate-y-1/2 text-2xl text-gray-600 cursor-pointer transition-all duration-300 ${isFocused
                  ? "translate-x-5 opacity-0 pointer-events-none"
                  : "translate-x-0 opacity-100"
                  }`}>
                <IoIosSearch />
              </span>
            )}
          </div>
        </div>

        {categoriesLoading ? (
					<div className="flex justify-center items-center h-[50vh]">
						<RingLoader color="#5097A4" size={150} />
					</div>
				) : categoriesData?.data?.length > 0 ? (
          <div className="flex flex-wrap justify-center gap-4 sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-6 py-8">
          {categoriesData?.data?.map((category: Category,i:number) => (
            <div className="flex justify-center w-full" key={i}>
              <CategoryCard key={category?.id} category={category} />
            </div>
          ))}
        </div>
				) : (
        	<NoData messageText="No categories found. Please check your filters." />
				)}
      </div>
    </div>
  );
};

export default CategoryPage;

