import { useEffect } from "react";
import SearchCard from "./SearchCard";
import { useSubSearchMutation } from "../../../Redux/Api/ProductAPI";

const RelatedSearch = ({ search }: any) => {
	const [searchMutation, { data: subSearches, isLoading: isSearchLoading }] =
		useSubSearchMutation();

	useEffect(() => {
		if (search) {
			searchMutation(search);
		}
	}, [search, searchMutation]);

	return (
		<div className="  flex flex-col gap-[12px] w-full ">
			<div className="font-medium text-xl pl-2">
				Related Search
			</div>

			<div className="grid grid-cols-1 xl:grid-cols-2  2xl:grid-cols-3 gap-4 p-2 sm:py-4 sm:pr-2">
				{subSearches?.product?.slice(0, 6).map((item: any) => {
					return <SearchCard name={item?.name} key={item._id} />;
				})}
			</div>
		</div>
	);
};

export default RelatedSearch;
