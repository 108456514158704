import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";

type HeaderItem = {
	name: string;
	route: string;
};

export const ProfileHeader = () => {
	const [selectedHeader, setSelectedHeader] = useState("My Profile");
	const [indicatorStyle, setIndicatorStyle] = useState({ left: 0, width: 0 });
	const arr: HeaderItem[] = [
		{ name: "My Profile", route: "/profile" },
		{ name: "Reward Cards", route: "/profile/rewards" },
		{ name: "Customer Entry", route: "/profile/entry" },
		{ name: "History", route: "/profile/history" },
		{ name: "Quotation Tracker", route: "/profile/quotation-tracker" },
		{ name: "Query", route: "/profile/query" },
	];
	const navigate = useNavigate();
	const location = useLocation();
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const activeItem = arr.find((item) => item.route === location.pathname);
		if (activeItem) {
			setSelectedHeader(activeItem.name);
		}
	}, [location.pathname]);

	useEffect(() => {
		const container = containerRef.current;
		if (container) {
			const activeButton = Array.from(container.children).find(
				(child) =>
					child instanceof HTMLButtonElement &&
					child.textContent === selectedHeader
			);

			if (activeButton instanceof HTMLButtonElement) {
				const { offsetLeft, offsetWidth } = activeButton;
				setIndicatorStyle({ left: offsetLeft, width: offsetWidth });
			}
		}
	}, [selectedHeader]);

	const handleClick = (name: string, route: string) => {
		setSelectedHeader(name);
		navigate(route);
	};

	return (
		<motion.div
			className="px-4 py-2 lg:hidden flex gap-4 bg-gradient-to-r shadow-sm border-b from-gray-50 to-gray-100 rounded-t-lg relative"
			initial={{ opacity: 0, y: -20 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.3 }}
		>
			<div
				className="flex gap-2 overflow-x-auto no-scrollbar  relative"
				ref={containerRef}
			>
				{arr.map((item, index) => (
					<button
						key={index}
						className={`px-2 py-2 rounded-md flex items-center whitespace-nowrap font-medium transition-all
							text-sm
                            ${selectedHeader === item.name
								? "text-turkishBlue-default font-bold"
								: "text-gray-600 hover:text-gray-800"
							}`}
						aria-current={selectedHeader === item.name ? "page" : undefined}
						onClick={() => handleClick(item.name, item.route)}
					>
						{item.name}
					</button>
				))}

				<motion.div
					className="absolute bottom-0 h-[3px] bg-turkishBlue-default rounded-md"
					style={{
						left: `${indicatorStyle.left}px`,
						width: `${indicatorStyle.width}px`,
					}}
					animate={{
						left: indicatorStyle.left,
						width: indicatorStyle.width,
					}}
					transition={{
						type: "spring",
						stiffness: 300,
						damping: 30,
					}}
				/>
			</div>
		</motion.div>
	);
};
